import {
   ComponentFactoryResolver,
   ComponentRef,
   Directive,
   ElementRef,
   Input,
   OnDestroy,
   OnInit,
   Renderer2,
   Self,
   ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { MessageCtrolComponent } from '../components/message-ctrol/message-ctrol.component';
import { MessageModel } from '../models/message-model';

@Directive({
   selector: '[validCtrlngModel]',
})
export class ValidCtrlngModelDirective implements OnInit, OnDestroy {
   subcriptions: Subscription = new Subscription();
   @Input('validCtrlngModel') messageCtrl: MessageModel[];
   ref: ComponentRef<MessageCtrolComponent>;
   initCtrol: boolean;

   constructor(
      @Self() private ctrl: NgControl,
      private vcr: ViewContainerRef,
      private resolver: ComponentFactoryResolver,
      private element: ElementRef,
      private renderer: Renderer2
   ) {}
   ngOnInit(): void {
      this.subcriptions.add(
         merge(this.ctrl.valueChanges).subscribe((submit) => {
            if (this.ctrl.touched || this.ctrl.dirty) {
               this.initCtrol = true;
               this.validCtrol();
            }
            if (this.initCtrol && (this.ctrl.touched || this.ctrl.dirty)) {
               this.validCtrol();
            }
         })
      );
   }
   ngOnDestroy(): void {
      this.subcriptions.unsubscribe();
   }

   validCtrol() {
      const errors = this.returnErrors();
      if (errors) {
         const error = Object.keys(errors)[0];
         let message = this.messageCtrl.find((mess) => mess.key === error)?.message;
         message = message ? message : 'Error sin mensaje';
         this.setMessageCtrl(message);
         return false;
      } else {
         this.setMessageCtrl();
         return true;
      }
   }
   setMessageCtrl(text?: string) {
      if (text) {
         if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(MessageCtrolComponent);
            this.ref = this.vcr.createComponent(factory);
         }
         this.ref.instance._text = text;

         const elem = this.element.nativeElement;
         const lbl = this.element.nativeElement.parentElement.querySelector('label');

         const compMessage = this.element.nativeElement.parentElement.querySelector('.message-form');
         this.renderer.addClass(elem, 'form-error');
         this.renderer.addClass(elem, 'ctrl-form-error');
         this.renderer.addClass(lbl, 'form-error');
         this.renderer.addClass(compMessage, 'form-error');

         const calendar = this.element.nativeElement.parentElement.querySelector('.p-inputtext');
         if (calendar) {
            this.renderer.addClass(calendar, 'form-error');
            this.renderer.addClass(calendar, 'ctrl-calendar-error');

            this.renderer.removeClass(elem, 'form-error');
            this.renderer.removeClass(elem, 'ctrl-form-error');

            const pButton = this.element.nativeElement.parentElement.querySelector('.p-button');
            if (pButton !== null) {
               this.renderer.addClass(pButton, 'form-error');
               this.renderer.addClass(pButton, 'ctrl-button-calendar-error');
            }
         }
      } else {
         this.ref = undefined;
         this.vcr.clear();
         const elem = this.element.nativeElement;
         const lbl = this.element.nativeElement.parentElement.querySelector('label');
         const compMessage = this.element.nativeElement.parentElement.querySelector('.message-form');

         if (elem?.getAttribute('class')?.includes('form-error')) {
            this.renderer.removeClass(elem, 'form-error');
         }
         if (elem?.getAttribute('class')?.includes('ctrl-form-error')) {
            this.renderer.removeClass(elem, 'ctrl-form-error');
         }
         if (lbl?.getAttribute('class')?.includes('form-error')) {
            this.renderer.removeClass(lbl, 'form-error');
         }
         if (compMessage?.getAttribute('class').includes('form-error')) {
            this.renderer.removeClass(compMessage, 'form-error');
         }
         const calendar = this.element.nativeElement.parentElement.querySelector('.p-inputtext');
         if (calendar) {
            if (calendar?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(calendar, 'form-error');
            }
            if (calendar?.getAttribute('class')?.includes('ctrl-calendar-error')) {
               this.renderer.removeClass(calendar, 'ctrl-calendar-error');
            }
            const pButton = this.element.nativeElement.parentElement.querySelector('.p-button');
            if (pButton?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(pButton, 'form-error');
            }
            if (pButton?.getAttribute('class')?.includes('ctrl-button-calendar-error')) {
               this.renderer.removeClass(pButton, 'ctrl-button-calendar-error');
            }
         }
      }
   }
   returnErrors = () => {
      let errors = undefined;
      for (let i = 0; i < this.messageCtrl.length; i++) {
         const element = this.messageCtrl[i];
         switch (element.key) {
            case 'maxlength':
               errors = this.validMaxLength();
               break;

            default:
               break;
         }
         if (errors) break;
      }
      return errors;
   };
   validMaxLength = () => (this.ctrl.value?.length >= this.messageCtrl.find((x) => x.key === 'maxlength')?.value ? { maxlength: true } : undefined);
}

import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { SiteComponent } from './site.component';
import { MesaHomeComponent } from './mesa-interoperabilidad/home/mesa-home.component';
import { MesaConsultComponent } from './mesa-interoperabilidad/mesa-consult/mesa-consult.component';
import { MesaVerReunionComponent } from './mesa-interoperabilidad/mesa-ver-reunion/mesa-ver-reunion.component';
import { UserEntitiesComponent } from './user-entities/user-entities.component';

const routes: Routes = [
   { path: '', component: SiteComponent },
   { path: 'user-entities', component: UserEntitiesComponent },
   { path: 'register', component: RegisterComponent },
   // { path: 'reset-init', component: PasswordResetInitComponent },
   // { path: 'reset-end', component: PasswordResetEndComponent },
   { path: 'mesas-interoperabilidad', component: MesaHomeComponent, children: [{ path: '', redirectTo: 'home', pathMatch: 'full' }] },
   { path: 'mesas-interoperabilidad/home', component: MesaHomeComponent },
   { path: 'mesas-interoperabilidad/consult/:id', component: MesaConsultComponent },
   { path: 'mesas-interoperabilidad/viewmeeting/:id/:idmesa', component: MesaVerReunionComponent },
   { path: 'datos-generales', loadChildren: () => import('./general-data/general-data.module').then((x) => x.GeneralDataModule) },
   {
      path: 'directorio-servicios',
      loadChildren: () => import('./consulta-servicios/consulta-servicios.module').then((x) => x.ConsultaServiciosModule),
   },
];
@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule],
})
export class SiteRoutingModule {}

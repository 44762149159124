import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
   selector: 'app-message-ctrol',
   templateUrl: './message-ctrol.component.html',
   styleUrls: ['./message-ctrol.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCtrolComponent {
   text: string;

   @Input() set _text(value) {
      if (value !== this.text) {
         this.text = value;
         this.cdr.detectChanges();
      }
   }

   constructor(private cdr: ChangeDetectorRef) {}
}

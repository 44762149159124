import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetFinishService } from '../../../services/password-reset-finish.service';
import { AlertaModalService } from '../../../services/alerta-modal.service';
import { Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-password-reset-end',
  templateUrl: './password-reset-end.component.html',
  styleUrls: ['./password-reset-end.component.scss'],
})
export class PasswordResetEndComponent implements OnInit, OnDestroy {
  subcriptions: Subscription = new Subscription();
  key?: string;
  visiblepwd = false;
  visiblecpwd = false;
  passwordchangeForm = this.fb.group({
    password: [
      '',
      [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    ],
    cpassword: [
      '',
      [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    ],
  });

  constructor(
    private passwordResetFinishService: PasswordResetFinishService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private alertaModal: AlertaModalService,
    private notificaciones: NotificacionesService,
  ) {}

  ngOnInit(): void {
    /*
    const  params = this.route.snapshot.params.clave;
    if (params) {
      this.key = params;
      console.log("key :"+this.key);
    }*/
    this.subcriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params['key']) {
          this.key = params['key'];
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subcriptions.unsubscribe();
  }
  change(): void {
    const password = this.passwordchangeForm.get('password').value;
    if (this.validar()) {
      this.subcriptions.add(
        this.passwordResetFinishService.change(this.key, password).subscribe(
          () => {
            this.notificaciones.notificacionExito('Contraseña restablecida con éxito');
            this.router.navigate(['../']);
          },
          (errors) => {
            this.alertaModal.modalError(
              '¡Error de validacion!',
              errors.error.mensaje
            );
          }
        )
      );
    }
  }
  changevisiblepwd(): void {
    this.visiblepwd = this.visiblepwd ? false : true;
  }
  changevisiblecpwd(): void {
    this.visiblecpwd = this.visiblecpwd ? false : true;
  }
  validar(): boolean {
    let sts = true;
    let msg = '';
    if (this.passwordchangeForm.get('password').getError('required')) {
      msg = 'El contraseña es requerida';
      sts = false;
    } else if (this.passwordchangeForm.get('cpassword').getError('required')) {
      msg = 'El contraseña de confirmacion requerida';
      sts = false;
    } else if (
      this.passwordchangeForm.get('password').value !=
      this.passwordchangeForm.get('cpassword').value
    ) {
      msg = 'Las contraseñas deben ser iguales';
      sts = false;
    }
    if (!sts) {
      this.alertaModal.modalError('¡Error de validación!', msg);
    }
    /* Swal.fire({
        title: '¡Error de validación!',
        text: msg,
        icon: 'error',
        confirmButtonText: 'Aceptar',
      }); */
    return sts;
  }
}

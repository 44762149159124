import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterCollection' })
export class filterCollectionPipe implements PipeTransform {
   transform(listData: any[], valueSearch?: { key: string; value: any; equals?: boolean }): any {
      if (!valueSearch?.key || !valueSearch?.value) {
         return listData;
      }
      if (listData && !valueSearch.equals) {
         return listData.filter((x) => x[valueSearch.key]?.toString().toLowerCase().indexOf(valueSearch.value?.toString().toLowerCase()) > -1);
      } else {
         return listData.filter((x) => x[valueSearch.key]?.toString().toLowerCase() === valueSearch.value?.toString().toLowerCase());
      }
   }
}

import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MessageCtrolComponent } from './components/message-ctrol/message-ctrol.component';
import { ValidCtrlDirective } from './directives/valid-ctrl.directive';
import { ValidFormDirective } from './directives/valid-forms.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SigmiTableComponent } from './components/sigmi-table/sigmi-table.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { NgxSpinnerModule } from 'ngx-spinner';

import { CalendarModule } from 'primeng/calendar';
import { SortTableDirective } from './directives/sort-table.directive';

import { filterCollectionPipe } from './pipes/filter-collection.pipe';
import { SigmiUploadComponent } from './components/sigmi-upload/sigmi-upload.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SigmiCardMenuComponent } from './components/sigmi-card-menu/sigmi-card-menu.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../site-private/shared/footer/footer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ValidCtrlngModelDirective } from './directives/valid-ctrl-ng-model.directive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MaskDirective } from './directives/mask.directive';
import { EntitySearchComponent } from './components/entity-search/entity-search.component';

const components = [MessageCtrolComponent, SigmiTableComponent, SigmiUploadComponent, SigmiCardMenuComponent, FooterComponent, BreadcrumbComponent, EntitySearchComponent ];
const pipes = [filterCollectionPipe];
const directives = [ValidCtrlDirective, ValidFormDirective, SortTableDirective, ValidCtrlngModelDirective, MaskDirective];
const modules = [
   CommonModule,
   FormsModule,
   ReactiveFormsModule,
   NgSelectModule,
   NgxPaginationModule,
   NgxSpinnerModule,
   CalendarModule,
   AutocompleteLibModule,
   NgbModule,
   RouterModule,
   CKEditorModule,

];

@NgModule({
   declarations: [...components, ...directives, ...pipes],
   imports: [...modules],
   exports: [...directives, ...components, ...modules, ...pipes],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

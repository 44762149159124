import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/entities';
import { AlertaModalService } from 'src/app/services/alerta-modal.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
   selector: 'app-header-site',
   templateUrl: './header-site.component.html',
   styleUrls: ['./header-site.component.scss'],
})
export class HeaderSiteComponent implements OnInit {
   userData: User;

   @Input()
   set _userData(userData: any) {
      this.userData = userData;
   }

   activeSubMenu: boolean = false;
   constructor(private authService: AuthService, private alertaModal: AlertaModalService, private router: Router) {}

   ngOnInit(): void {
      this.userData = !this.userData ? this.authService.currentUserValue : this.userData;
   }
   logout() {
      this.alertaModal.modalPregunta('¡Cerrar sesión!', '¿Realmente desea cerrar la sesión?');
   }
   returnEmail = () => this.userData.correoEletronico;
   returnActiveButton = () => this.router.url.includes('user-entities');
}

<ngx-spinner
    bdColor="rgba(256, 256, 256, 0.5)"
    type="ball-clip-rotate"
    color= "#4672C8"
    size="medium"
    showSpinner
    >
    <!-- color, size, type, name (default primary), zindex, template, showSpinner, disableAnimations -->
</ngx-spinner>
<!-- <app-header></app-header> -->
<router-outlet></router-outlet>
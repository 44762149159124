<div class="form-group mb-0">
   <label for="input-file-simple-label" *ngIf="!download">
      <span class="body-1 mb-1">{{ title }} </span>
   </label>
   <div class="custom-file file-govco" *ngIf="!download">
      <input
         type="file"
         name="nameCtrl"
         class="custom-file-input input-file-govco"
         (click)="onClick()"
         [id]="nameCtrl"
         [multiple]="multiple"
         [accept]="accept"
         hidden
      />
      <label class="custom-file-label label-file-govco" [ngClass]="{ loaded: inProgress }" [for]="nameCtrl">
         <ng-container *ngIf="!inProgress">
            <span class="bi bi-archive"></span>Arrastre aquí su(s) archivo(s) o haga click para añadir.
         </ng-container>

         <span *ngIf="inProgress" id="spinner-loader-file">
            <div class="spinner-border text-primary" role="status"></div>
            Subiendo archivo(s)
         </span>
      </label>
   </div>
   <ul style="margin: 15px 0; padding: 0">
      <li *ngFor="let file of filesUpload; index as i" class="mb-1" style="min-width: 100px; list-style: none; left: 0px">
         <div class="gov-co-label gov-co-negative" style="padding: 8px 12px;">
            <div class="content-text">
               <label [title]="file?.name" class="document-text body-1 mb-1">{{ i + 1 }} - {{ file?.name }}</label>
               <span>({{ file?.size }}k)</span>
            </div>
            <span *ngIf="!download" class="sigmi-icon sigmi-close" (click)="deletefile(file)"></span>
            <ng-container *ngIf="publico === false; else tmpltPublico">
              <span *ngIf="download" class="sigmi-icon sigmi-icon-down" (click)="downloadFile(file)"></span>
            </ng-container>
            <ng-template #tmpltPublico>
              <span *ngIf="download" class="sigmi-icon sigmi-icon-down" (click)="downloadFilePublic(file)"></span>
            </ng-template>
         </div>
      </li>
   </ul>
</div>

import { Component, Input, OnInit } from '@angular/core';
import * as fromModels from 'src/app/shared/models';
import { User } from 'src/app/models/entities';
import { AuthService } from 'src/app/services/auth.service';
import { urlsPrivate } from 'src/environments/url-services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  userData: User;
  modelBreadCrumb: fromModels.Breadcrumb;  
  @Input() 
  public set _modelBreadCrumb(model: fromModels.Breadcrumb){
    this.modelBreadCrumb = model;
  }

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.userData = this.authService.currentUserValue;
  }

}

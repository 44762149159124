import { Component, Input, OnInit } from '@angular/core';
import { Funcionalidades, SubFuncionalidades } from 'src/app/models/entities';
import { AuthService } from 'src/app/services/auth.service';
import { CardMenu } from './model-card-menu';

@Component({
   selector: 'app-sigmi-card-menu',
   templateUrl: './sigmi-card-menu.component.html',
   styleUrls: ['./sigmi-card-menu.component.scss'],
})

export class SigmiCardMenuComponent implements OnInit {
   @Input('menu') menu: Array<CardMenu> = null;
   @Input('idFunctionality') idFunctionality: number;
   @Input('idSubfunctionality') idSubfunctionality: number;
   @Input('descriptions') descriptions: Array<any>;

   constructor(private authService: AuthService) { }

   ngOnInit(): void {
      if (this.idFunctionality > -1) {
         this.createMenu(this.authService.currentUserValue.funcionalidades);
      }
   }

   private createMenu(data: Array<Funcionalidades>): void {
      let subFunctionalities = this.getSubFuncionalities(data);
      if (subFunctionalities.length > 0) {
         let partialMenu: Array<CardMenu> = new Array<CardMenu>();
         subFunctionalities.forEach((subFunctionality) => {
            partialMenu.push(this.getCardMenuFromSubFunctionality(subFunctionality));
         });
         this.menu = partialMenu;
      }
   }

   private getSubFuncionalities(data: Array<Funcionalidades>): Array<SubFuncionalidades> {
      let subFunctionalities: Array<SubFuncionalidades> = new Array<SubFuncionalidades>();
      data.forEach((functionality) => {       
         if (functionality.id === this.idFunctionality && !this.idSubfunctionality) {
            subFunctionalities = functionality.subFuncionalidades;
         }
         else if(functionality.id === this.idFunctionality && this.idSubfunctionality){
            functionality.subFuncionalidades.forEach(items => {
               if(items.id === this.idSubfunctionality){
                  const listaItems = items.listaItems.map(datos =>{
                     return {
                        id: datos.id,
                        nombre: datos.nombre,
                        nombreFuncionalidad: datos.nombre,
                        url: datos?.url,
                        idPadre: datos.idSubmodulo,
                        consulta: true,
                        descripcion: datos.descripcion,
                     }
                  });
                  subFunctionalities = listaItems;
               }
            })
         }
      });
      return subFunctionalities;
   }

   private getCardMenuFromSubFunctionality(data: SubFuncionalidades): CardMenu {
      let cardMenu = {} as CardMenu;
      cardMenu.name = data.nombreFuncionalidad;
      cardMenu.description = data.descripcion;
      cardMenu.url = data.url;
      cardMenu.consulta = data.consulta;
      return cardMenu;
   }
}

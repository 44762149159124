import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { urlsPublic } from 'src/environments/url-services';
import { Iregister } from '../models/iregister';
import { SharedService } from '../shared/services/shared.services';

@Injectable({
   providedIn: 'root',
})
export class RegisterService extends SharedService {
   constructor(http: HttpClient) {
      super(http);
   }
   URL_PUBLICA: String = environment.server_api_url + '/api-public/';

   save(register: Iregister): Observable<{}> {
      return this.http.post(`${environment.server_api_url}${urlsPublic.registrar}`, register);
   }

   listarEntidades$(): Observable<any> {
      return this.http.get(`${environment.server_api_url}${urlsPublic.listarEntidades}`);
   }

   tipoidentificacion(): Observable<any> {
      return this.http.get(`${environment.server_api_url}${urlsPublic.get_tipo_identificacion}`);
   }
   postPublicData(servicio: string, data) {
      return this.http.post<any>(this.URL_PUBLICA + servicio, data).pipe(
         map((responseData: any) => {
            return responseData;
         })
      );
   }
}

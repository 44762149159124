<div class="sigmi-footer">
   <footer class="gov-co-footer container">
      <div class="gov-co-footer-pie footer-venta  ps-0 pe-0">
         <div class="gov-co-footer-venta" style="padding-right: 10px; text-align: left !important;">
            <img class="img-fluid  gov-co-logo-pie-blanco" src="../../../assets/images/LogoBlanco.svg" alt=""
               srcset="" />
            <img class="img-fluid  gov-co-logo-pie-blanco" src="../../../assets/images/TIC_BN.svg" alt="" srcset="" />
            <img class="img-fluid gov-co-logo-pie-mesa" src="../../../assets/images/ZiqwTo.png" alt="" srcset="" />
            <img class="img-fluid gov-co-logo-pie-blanco" src="../../../assets/images/logo_govco_blanco.svg" alt=""
               srcset="" style="text-align: left" />
         </div>
         <div class="gov-co-presen-venta">
            <div class="container-fluid">
               <div class="row">
                  <div class="col p-0">
                     <h5 class="titulo-venta">Sistema de Información de Gestión del Marco de Interoperabilidad</h5>
                  </div>
               </div>
               <div class="row">
                  <div class="col p-0">
                     <div class="gov-detalles-venta">
                        <p>Dirección: Edificio Murillo Toro Cra. 8a entre calles </p>
                        <p>12 y 13, Bogotá, Colombia.</p>
                        <p>Código Postal: 111711 </p>
                        <p>Horario de Atención: Lunes a Viernes 08:00 am - 05:00 pm.</p>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col p-0">
                     <div class="gov-co-social-media-venta" style="color: white;">
                        <a class="gov-co-link-venta" style="text-decoration: underline;">
                           <svg id="twitter" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 20 20">
                              <rect id="Contenedor" width="20" height="20" fill="none" />
                              <path id="twitter-2" data-name="twitter"
                                 d="M17.529,52.037c.012.174.012.347.012.521A11.315,11.315,0,0,1,6.149,63.95,11.316,11.316,0,0,1,0,62.153a8.283,8.283,0,0,0,.967.05,8.019,8.019,0,0,0,4.971-1.711,4.011,4.011,0,0,1-3.744-2.777,5.049,5.049,0,0,0,.756.062A4.235,4.235,0,0,0,4,57.64,4,4,0,0,1,.793,53.71v-.05a4.033,4.033,0,0,0,1.81.508,4.01,4.01,0,0,1-1.24-5.355A11.382,11.382,0,0,0,9.62,53a4.52,4.52,0,0,1-.1-.917,4.008,4.008,0,0,1,6.93-2.74,7.884,7.884,0,0,0,2.541-.967,3.993,3.993,0,0,1-1.76,2.207,8.027,8.027,0,0,0,2.306-.62A8.607,8.607,0,0,1,17.529,52.037Z"
                                 transform="translate(0 -46.016)" fill="#fff" />
                           </svg>
                           <!--<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>-->
                           @TWITER
                        </a>
                        <a class="gov-co-link-venta" style="text-decoration: underline;">
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                              <path id="instagram"
                                 d="M9.927,36.7a5.128,5.128,0,1,0,5.127,5.128A5.119,5.119,0,0,0,9.927,36.7Zm0,8.461a3.334,3.334,0,1,1,3.333-3.334,3.339,3.339,0,0,1-3.333,3.334Zm6.532-8.671a1.2,1.2,0,1,1-1.2-1.2A1.193,1.193,0,0,1,16.459,36.487Zm3.4,1.214a5.92,5.92,0,0,0-1.615-4.191A5.956,5.956,0,0,0,14.05,31.9c-1.651-.094-6.6-.094-8.25,0a5.947,5.947,0,0,0-4.19,1.611A5.939,5.939,0,0,0,0,37.7C-.1,39.348-.1,44.3,0,45.949A5.92,5.92,0,0,0,1.61,50.139,5.963,5.963,0,0,0,5.8,51.755c1.651.094,6.6.094,8.25,0a5.917,5.917,0,0,0,4.19-1.616,5.958,5.958,0,0,0,1.615-4.191C19.948,44.3,19.948,39.353,19.855,37.7ZM17.722,47.72a3.375,3.375,0,0,1-1.9,1.9c-1.316.522-4.439.4-5.894.4s-4.582.116-5.894-.4a3.375,3.375,0,0,1-1.9-1.9c-.522-1.317-.4-4.44-.4-5.9s-.116-4.583.4-5.9a3.375,3.375,0,0,1,1.9-1.9c1.316-.522,4.439-.4,5.894-.4s4.582-.116,5.894.4a3.375,3.375,0,0,1,1.9,1.9c.522,1.317.4,4.44.4,5.9S18.244,46.408,17.722,47.72Z"
                                 transform="translate(0.075 -31.825)" fill="#fff" />
                           </svg>

                           <!--<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                        </svg>-->
                           @INSTAGRAM
                        </a>
                        <a class="gov-co-link-venta" style="text-decoration: underline;">
                           <svg id="facebook" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              viewBox="0 0 20 20">
                              <rect id="Contenedor" width="16" height="16" fill="none" />
                              <path id="facebook-f"
                                 d="M32.032,10.274l.507-3.306H29.367V4.824a1.653,1.653,0,0,1,1.864-1.786h1.442V.223A17.585,17.585,0,0,0,30.113,0c-2.612,0-4.32,1.583-4.32,4.449V6.969h-2.9v3.306h2.9v7.991h3.574V10.274Z"
                                 transform="translate(-17.781)" fill="#fff" />
                           </svg>
                           <!--<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg>-->
                           @FACEBOOK
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="gov-co-conta-venta">
            <div class="container-fluid">
               <div class="row">
                  <div class="col p-0">
                     <h5 class="titulo-venta">
                        <span class="sigmi-icon sigmi-phone" style="font-size: 18px;"></span>
                        Contacto
                     </h5>
                  </div>
               </div>
               <div class="row">
                  <div class="col p-0">
                     <div class="gov-detalles-venta-conta">
                        <p>Teléfono Conmutador: +(571) 344 3460 </p>
                        <p>Línea gratuita: 01-8000-914014 </p>
                        <p>Línea anticorrupción: 01-800-0912667 </p>
                        <p>Correo: soporteccc@mintic.gov.co</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;lenguaje@mintic.gov.co</p>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col p-0">
                     <div class="gov-co-pilo-venta" style="color: white;">
                        <a href="/" class="goc-co-link-poli-venta">Mapa del sitio</a>
                        <a href="/" class="goc-co-link-poli-venta link-poli ">Políticas</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertaModalService } from '../services/alerta-modal.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';

@Component({
   selector: 'app-site',
   templateUrl: './site.component.html',
   styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit {
   constructor(private router: Router, private alertaModal: AlertaModalService, private oauthService: OAuthService) {}

   ngOnInit() {
      if (localStorage.getItem('payload')) {
         this.router.navigate(['app']);
      }
     // console.log(localStorage.getItem('entitiesData'));
      
   }
   registerUser() {
      this.alertaModal
         .modalWarning(
            'Nivel de seguridad',
            `<p class="m-0">Recuerde que el registro del nuevo usuario debe realizarse como persona natural con tipo de acceso</p>
            <strong>“Registro con documento de identidad”</strong> 
            <p>correspondiente al nivel medio de seguridad que se requiere para la autenticación en SIGMI.</p>`,
            'continuar',
            false,
            true
         )
         .then(({ isConfirmed }) => {
            if (isConfirmed) {
               this.enterCitizenFolder('register');
            }
         });
   }
   enterCitizenFolder(type: 'register' | 'login') {
     // console.log('whats going on');
      
      const entitiesData = JSON.parse(localStorage.getItem('entitiesData'));
      localStorage.clear();
      localStorage.setItem('entitiesData', JSON.stringify(entitiesData));
      this.oauthService.configure(authConfig);
     // this.oauthService.setupAutomaticSilentRefresh(undefined, 'access_token');
      this.oauthService.loadDiscoveryDocument().then((response) => {
         sessionStorage.setItem('flow', 'implicit');
         sessionStorage.setItem('type', type);
         sessionStorage.removeItem('loaded');         
         this.oauthService.initLoginFlow('/some-state;p1=1;p2=2?p3=3&p4=4');
      });
   }
}

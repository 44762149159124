<app-header-site></app-header-site>
<!-- Body -->
<div class="login" style="height: 587px; background-color: #f6f8f9">
  <div class="container" style="padding-left: 10px;">
    <div class="row mt-4">
      <div class="col-sm-12 col-md-7 col-lg-5 col-xl-5">
        <div class="headline-xl">
          Sistema de Información de Gestión del Marco de Interoperabilidad.
        </div>
        <div class="body-1 mt-3">
          Sistema de información que permite publicar elementos de dato
          estandarizados de acuerdo con el estándar de Lenguaje Común de
          Intercambio de Información - LCII bajo el Marco de Interoperabilidad
          para Gobierno Digital.
        </div>
      </div>
      <div class="col-md-7 col-sm-6">
        <div class="card m-4">
          <div class="card-body">
            <div class="headline-l-govco mb-2">Ingrese la nueva contraseña</div>
            <form [formGroup]="passwordchangeForm">
              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="password" class="body-1-gov-co text-left">Nueva contraseña *</label>
                  <div class="col" style="position: relative">
                    <input type="{{ visiblepwd ? 'text' : 'password' }}" class="form-control" id="passsword"
                      formControlName="password" jhiMask />
                    <span class="btn-eye-auth">
                      <svg width="2em" height="2em" (click)="changevisiblepwd()" viewBox="0 0 16 16"
                        class="bi bi-eye-fill" style="background-color: #ffffff" fill="#0943B5"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path fill-rule="evenodd"
                          d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="cpassword" class="body-1-gov-co text-left">Confirmar nueva contraseña *</label>
                  <div class="col" style="position: relative">
                    <input type="{{ visiblecpwd ? 'text' : 'password' }}" class="form-control" id="cpasssword"
                      formControlName="cpassword" jhiMask />
                    <span class="btn-eye-auth">
                      <svg width="2em" height="2em" (click)="changevisiblecpwd()" viewBox="0 0 16 16"
                        class="bi bi-eye-fill" style="background-color: #ffffff" fill="#0943B5"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path fill-rule="evenodd"
                          d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-4 mb-2">
                <button class="btn btn-round btn-high" (click)="change()" [disabled]="passwordchangeForm.invalid">
                  RESTABLECER CONTRASEÑA
                </button>
              </div>
              <div class="mb-2">
                <a [routerLink]="['../']">ATRÁS</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { PrimeNGConfig } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { authConfig } from './site/auth.config';
import { AuthModel } from './shared/models';
import { AlertaModalService } from './services/alerta-modal.service';
import { User } from './models/entities';

export let browserRefresh = false;

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
   subscriptions: Subscription = new Subscription();
   subscriptionsRouter: Subscription = new Subscription();
   title = 'Sigmi';
   private title_base = 'Sigmi 2.0 v' + environment.app_version;

   get claims(): AuthModel {
      return this.oauthService.getIdentityClaims() as AuthModel;
   }

   get acr(): string {
      return this.claims.acr;
   }
   public constructor(
      private config: PrimeNGConfig,
      private authService: AuthService,
      private titleService: Title,
      private spinnerService: NgxSpinnerService,
      private router: Router,
      private oauthService: OAuthService,
      private alertaModal: AlertaModalService
   ) {
      this.subscriptionsRouter.add(
         router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((event: NavigationStart) => {
            if (event.url.includes('/app') || event.url.includes('/inicio')) {
               this.configureImplicitFlow();
               browserRefresh = !router.navigated;
               this.subscriptionsRouter.unsubscribe();
            }
         })
      );
   }

   ngOnInit() {
      const errorCorreo = sessionStorage.getItem('errorCorreo');
      if (errorCorreo) {
         sessionStorage.removeItem('errorCorreo');
         this.oauthService.configure(authConfig);
         this.oauthService.loadDiscoveryDocument().then((response) => {
            sessionStorage.setItem('flow', 'implicit');
            sessionStorage.setItem('type', 'register');
            sessionStorage.setItem('loading', 'true');
            this.oauthService.initLoginFlow('/some-state;p1=1;p2=2?p3=3&p4=4');
         });
      }
      const loading = sessionStorage.getItem('loading');
      if (loading) {
         this.spinnerService.show();
      }
      if (localStorage.getItem('access_token_sigmi')) {
         this.authService.setUserDetails();
      }
      this.setTitle();

      this.config.setTranslation({
         accept: 'Accept',
         reject: 'Cancel',
         //translations
         monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
         monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
         dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
         dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sa'],
         dayNamesMin: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sa'],
         weekHeader: 'Sm',
      });
      this.subscriptions.add(
         this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((e) => {
            this.oauthService.loadUserProfile();

            const loaded = sessionStorage.getItem('loaded');
            if (!loaded) {
               if (this.acr === '2') {
                  this.authService
                     .login({
                        identificacion: this.claims.Identificacion,
                        externalToken: this.oauthService.getAccessToken(),
                     })
                     .toPromise()
                     .then((response) => {
                        const type = sessionStorage.getItem('type');
                        if (type === 'register') {
                           this.router.navigate(['register']);
                        } else {
                           const { status } = response;
                           if (status.code === '404') {
                              this.router.navigate(['register']);
                           } else if (status.code === '200') {
                              if (response.entities.some((x) => !x.usuarioPorAprobar) && response.entities.some((x) => x.estadoUsuario)) {
                                 const { payload } = response;
                                 const infoUser = Array.isArray(payload) && payload.length > 0 ? payload.filter((x) => x)[0] : payload;
                                 sessionStorage.setItem('loaded', 'true');
                                 localStorage.setItem('info_user', btoa(encodeURIComponent(JSON.stringify(response))));
                                 localStorage.setItem('access_token_sigmi', response.access_token);

                                 const user: User = { ...infoUser.usuario };
                                 if (payload.length > 1) {
                                    this.router.navigate(['user-entities']);
                                    localStorage.setItem('payload', btoa(encodeURIComponent(JSON.stringify(user))));
                                 } else {
                                    user.rol = infoUser.rol;
                                    user.funcionalidades = infoUser.funcionalidades;

                                    localStorage.setItem('payload', btoa(encodeURIComponent(JSON.stringify(user))));
                                    this.router.navigate(['app']);
                                    this.authService.setUserDetails();
                                 }
                              } else {
                                 const entities = response.entities;
                                 if (entities.length > 0) {
                                    this.alertaModal
                                       .modalErrorBtn(
                                          'Error',
                                          `<div class="d-flex gap-2 flex-wrap justify-content-center">
                                             ${response.entities
                                             .map((x, i) => {
                                                return `
                                                   <button class="content-entity-modal "  disabled="${!x.estadoUsuario}">
                                                      <div class="content-entity-img">
                                                         <img src="assets/images/Icon_map-museum${!x.estadoUsuario ? '_white' : ''
                                                   }.svg" alt="" srcset="" width="40px" />
                                                         <span>
                                                            ${x.nombre[0].toUpperCase() + x.nombre.slice(1).toLowerCase()}
                                                         </span>
                                                      </div>
                                                      <span class="error-entity text-center">
                                                         ${x.usuarioPorAprobar
                                                      ? 'Usuario pendiente por aprobación-MinTIC'
                                                      : !x.estadoUsuario
                                                         ? 'Usuario inactivo'
                                                         : ''
                                                   }
                                                      </span>
                                                   </button>                                          
                                                                                          
                                                `;
                                             })
                                             .join('')}
                                          </div>`,
                                          'continuar',
                                          true,
                                          'title-error'
                                       )
                                       .then(({ isConfirmed }) => {
                                          if (isConfirmed) {
                                             this.oauthService.logOut(false);
                                             this.oauthService.revokeTokenAndLogout();
                                          }
                                       });
                                 }
                              }
                           }
                           sessionStorage.removeItem('type');
                        }
                     })
                     .catch(console.error);
               } else {
                  this.alertaModal
                     .modalErrorBtn(
                        'No se puede continuar con el proceso en SIGMI',
                        `<p class="m-0 text-error">Se ha registrado con el tipo de acceso correo electrónico, para el ingreso a SIGMI 
                        debe registrarse con tipo de acceso <strong>"Documento identidad"</strong>.</p>`,
                        'Registrar usuario',
                        true,
                        'title-error'
                     )
                     .then(({ isConfirmed }) => {
                        if (isConfirmed) {
                           sessionStorage.setItem('errorCorreo', 'true');
                           this.oauthService.logOut(false);
                           this.oauthService.revokeTokenAndLogout();
                        }
                     });
               }
            }
         })
      );
   }
   ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
   }
   private setTitle(): void {
      this.titleService.setTitle(this.title_base);
   }
   private configureImplicitFlow() {
      const loading = sessionStorage.getItem('loading');
      if (loading) {
         this.spinnerService.show();
      }

      this.oauthService.configure(authConfig);
      this.oauthService.setStorage(localStorage);

      this.oauthService.setupAutomaticSilentRefresh();
      this.spinnerService.show();

      setTimeout(() => {
         this.spinnerService.hide();
         this.tryLogin();
      }, 5000);

      this.subscriptions.add(
         this.oauthService.events.pipe(filter((e) => e.type === 'session_changed')).subscribe((e) => {
            console.log('Your session has been terminated!', e);
            this.authService.logout();
         })
      );
   }

   private tryLogin() {      
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then((response) => {
         this.router.navigate(['/']);
      }).catch(error => {
         console.log(error);

         this.oauthService.loadDiscoveryDocument().then((response) => {
            sessionStorage.setItem('flow', 'implicit');
            sessionStorage.setItem('type', 'login');
            sessionStorage.removeItem('loaded');
            this.oauthService.initLoginFlow('/some-state;p1=1;p2=2?p3=3&p4=4');
            this.tryLogin();
         });
      });
   }
}

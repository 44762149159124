<app-header-site></app-header-site>
<!-- Body -->
<div class="login" style="min-height: 587px; background-color: #f6f8f9">
  <div class="container" style="padding-left: 10px;">
    <div class="row mt-4">
      <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="headline-xl">
          Sistema de Información de Gestión del Marco de Interoperabilidad.
        </div>
        <div class="body-1 mt-3">
          Sistema de información que permite publicar elementos de dato
          estandarizados de acuerdo con el estándar de Lenguaje Común de
          Intercambio de Información - LCII bajo el Marco de Interoperabilidad
          para Gobierno Digital.
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7">
        <div class="cardd">
          <div class="cardd-body">
            <div class="headline-l-govco mb-2">Restablecer contraseña</div>
            <div class="body-1 mb-4 pb-2">
              Ingrese el correo asociado al usuario y la organización.
            </div>
            <form [formGroup]="passwordresetForm">
              <div class="row mb-4">
                <div class="col-md-6 pe-3">
                  <label
                    for="correoEletronico"
                    class="body-1 text-left textoLabel"
                    >Correo Electrónico *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="correoEletronico"
                    placeholder="correo@entidad.com"
                    formControlName="correoEletronico"
                    [validCtrl]="[
                            {
                               key: 'required',
                               message: 'El correo electrónico es requerido'
                            },
                            { key: 'pattern', message: 'No tiene el formato correcto user@dominio.com' },
                         ]"
                  />
                </div>
                <div class="col-md-6 pe-3">
                  <div class="ng-autocomplete form-item">
                     <label for="idEntidad" class="body-1 mb-1">Entidad *</label>
                      <ng-autocomplete
                         id="idEntidad"
                         [data]="entidadfinderData"
                         minQueryLength="3"
                         [searchKeyword]="'nombre'"
                         (selected)="changeEntidadesMesa($event)"
                         (inputChanged)="onChangeSearch($event)"
                         (inputCleared)="clearSearch()"
                         [itemTemplate]="itemTemplate"
                         debounceTime="500"
                         [notFoundTemplate]="notFoundTemplate"
                         placeholder="Buscar entidad"
                         formControlName="entidadId"
                         [validCtrl]="[
                            {
                               key: 'required',
                               message: 'Entidad es requerido'
                            }
                         ]"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                         <a [innerHTML]="item.nombre"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound> No hay resultados </ng-template>
                   </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6 mt-4">
                  <button
                    class="btn btn-round btn-high"
                    (click)="reset()"
                    [disabled]="passwordresetForm.invalid"
                  >
                    RESTABLECER CONTRASEÑA
                  </button>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-8 mb-3">
                  <a class="BOTON-TEXT" [routerLink]="['../']">ATRÁS</a>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SiteRoutingModule } from './site-routing.module';
import { RecaptchaModule } from 'ng-recaptcha'; //,  RecaptchaFormsModule
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';

import { SiteComponent } from './site.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PasswordResetInitComponent } from './password-reset/password-reset-init/password-reset-init.component';
import { PasswordResetEndComponent } from './password-reset/password-reset-end/password-reset-end.component';
import { HeaderSiteComponent } from './shared/header-site/header-site.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../shared/shared.module';
import { MesaHomeComponent } from './mesa-interoperabilidad/home/mesa-home.component';
import { MesaConsultComponent } from './mesa-interoperabilidad/mesa-consult/mesa-consult.component';
import { MesaVerReunionComponent } from './mesa-interoperabilidad/mesa-ver-reunion/mesa-ver-reunion.component';

import { OAuthModule } from 'angular-oauth2-oidc';
import { UserEntitiesComponent } from './user-entities/user-entities.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
   declarations: [
      SiteComponent,
      RegisterComponent,
      PasswordResetInitComponent,
      PasswordResetEndComponent,
      HeaderSiteComponent,
      MesaHomeComponent,
      MesaConsultComponent,
      MesaVerReunionComponent,
      UserEntitiesComponent,
   ],
   imports: [
      CommonModule,
      HttpClientModule,
      SiteRoutingModule,
      RecaptchaModule,
      //RecaptchaFormsModule,
      FormsModule,
      ReactiveFormsModule,
      NgxMaskModule.forRoot(options),
      NgSelectModule,
      SharedModule,
      AutocompleteLibModule,
      NgbNavModule,
      OAuthModule.forRoot({
         resourceServer: {
            allowedUrls: ['http://www.angular.at/api'],
            sendAccessToken: true,
         },
      }),
   ],
   exports: [SiteComponent, HttpClientModule],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
   providers: [
      {
         provide: RECAPTCHA_SETTINGS,
         useValue: { siteKey: environment.RECAPTCHA_KEY } as RecaptchaSettings,
      },
   ],
})
export class SiteModule {}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class mesaFunctions {
  URL_PUBLICA: String = environment.server_api_url + "/api-public/"

  constructor(
    private http: HttpClient,
  ) { }

  getDataPublic(servicio: string, data?) {
    return this.http.get<any>(this.URL_PUBLICA + servicio, { params: data })
      .pipe(map((responseData: any) => {
        return responseData;
      }));
  }

  postPublicData(servicio: string, data) {
    return this.http.post<any>(this.URL_PUBLICA + servicio, data)
      .pipe(map((responseData: any) => {
        return responseData;
      }));
  }

  get_localstorage = (name: string) => JSON.parse(localStorage.getItem(name));
  add_localstorage = (name: string, items: any) => localStorage.setItem(name, JSON.stringify(items));
  delete_localstorage = (name: string) => localStorage.removeItem(name);
}

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordResetInitService } from '../../../services/password-reset-init.service';
import { AlertaModalService } from '../../../services/alerta-modal.service';
import { Subscription } from 'rxjs';
import { RegisterService } from '../../../services/register.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-reset-init',
  templateUrl: './password-reset-init.component.html',
  styleUrls: ['./password-reset-init.component.scss'],
})
export class PasswordResetInitComponent implements OnDestroy {
  subcriptions: Subscription = new Subscription();
  listaEntidades: any;
  passwordresetForm = this.fb.group({
    correoEletronico: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ],
    ],
    entidadId: [null, [Validators.required]],
  });

  styleGuide = {
    caretClass: 'caret',
    selectBoxClass: 'dropdown-wrapper',
    selectMenuClass: 'dropdown',
    optionsClass: 'option',
  };
  entidadfinderData: any;
  constructor(
    private registerService: RegisterService,
    private fb: FormBuilder,
    private passwordresetinitservice: PasswordResetInitService,
    private alertaModal: AlertaModalService,
    private notificaciones: NotificacionesService,
    private router: Router,
  ) {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subcriptions.unsubscribe();
  }
  reset(): void {
    if (this.validar()) {
      const correo = this.passwordresetForm.get('correoEletronico').value;
      const entidad = this.passwordresetForm.get('entidadId').value?.id;
      if(entidad === undefined){
        this.notificaciones.notificacionError('Debe seleccionar una entidad del menú desplegable');
        return;
      }
      this.subcriptions
        .add(
          this.passwordresetinitservice.solicitud(correo, entidad).subscribe(
            () => {
              const email = this.passwordresetForm.get('correoEletronico').value;
              this.passwordresetForm.reset();
              var mensajeModal =
                'Hemos enviado un correo electrónico a <b>' +
                email +
                '</b> con un enlace para que recuperes el acceso a tu cuenta SIGMI.';
              this.alertaModal.modalConfirmacion(
                '¡Solicitud enviada!',
                mensajeModal
              ).then(result =>{
                if(result.isConfirmed){
                  this.router.navigate(['/']);
                }
                if(result.isDismissed){
                  this.router.navigate(['/']);
                }
              });
            },
            () => {
              this.alertaModal.modalError(
                '¡Solicitud enviada!',
                '¡No es posible solicitar el cambio de contraseña!'
              );
            }
          )
        )
    }
  }

  validar(): boolean {
    let sts = true;
    let msg = '';
    if (this.passwordresetForm.get('correoEletronico').getError('required')) {
      msg = 'El correo electrónico es requerido.';
      sts = false;
    } else if (
      this.passwordresetForm.get('correoEletronico').getError('pattern')
    ) {
      msg =
        'El correo electrónico debe iniciar con una letra en mayúscula seguido de  una letra y números.';
      sts = false;
    } else if (this.passwordresetForm.get('entidadId').getError('required')) {
      msg = 'El entidad es requerido.';
      sts = false;
    }
    if (!sts) {
      this.alertaModal.modalError('¡Error de validación!', msg);
    }
    return sts;
  }

  changeEntidadesMesa(dato: any) {
    this.passwordresetForm.get('entidadId').setValue(dato);
  }
  onChangeSearch(type?: any) {
    var requestData = {
      nombreEntidad: type,
    };
    this.registerService.postPublicData('entidad/finderTopFive', requestData)
      .toPromise()
      .then((responseData: any) => {
        this.entidadfinderData = responseData.map(({ nombre, ...data }: any) => {
          const nombreCap = nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase();
          return {
             nombre: nombreCap,
             ...data,
          };
       });
      });
  }
  clearSearch() {
    this.entidadfinderData = undefined;
    this.passwordresetForm.get('entidadId').setValue(undefined);
  }
}

<div class="row mb-5 pb-3 pt-4" *ngIf="menu != null">
   <div class="row-md-12 cardGroup">
      <div class="card gov-co-tramite-card me-4 mb-4" *ngFor="let item of menu">
         <div class="card-header text-center p-0">
            <a class="headerTitle titleCard" [routerLink]="[item.url]">{{ item.name }}</a>
         </div>
         <div class="card-body">
            <p class="card-text">{{ item.description }}</p>
         </div>
      </div>
   </div>
</div>

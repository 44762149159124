import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Entity } from 'src/app/models/entitie.model';
import { User } from 'src/app/models/entities';
import { AuthService } from 'src/app/services/auth.service';

@Component({
   selector: 'app-user-entities',
   templateUrl: './user-entities.component.html',
   styleUrls: ['./user-entities.component.scss'],
})
export class UserEntitiesComponent implements OnInit {
   entities: Array<Entity>;
   nameUser: string;
   filterEntity: string;
   info_user: any;
   user: any;
   constructor(private authService: AuthService, private router: Router) {}

   ngOnInit(): void {
      this.info_user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('info_user'))));
      const { primerApellido, primerNombre, segundoApellido, segundoNombre }: User = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('payload'))));
      this.nameUser = `${primerNombre || ''}${segundoNombre ? ' ' : ''}${segundoNombre || ''}${primerApellido ? ' ' : ''}${primerApellido || ''}${
         segundoApellido ? ' ' : ''
      }${segundoApellido || ''}`;
      this.clearFilter();
      this.user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('payload'))));
      if (this.info_user.entities.length > 0) {
         this.loadUser(this.info_user.payload.filter((x) => x)[0].entidadDTO, false);
      }
   }
   clearFilter() {
      this.entities = this.info_user.entities.map(({ id, nombre, usuarioPorAprobar, usuarioRechazado, estadoUsuario }: Entity) => {
         return { id, nombre: nombre[0].toUpperCase() + nombre.slice(1).toLowerCase(), usuarioPorAprobar, usuarioRechazado, estadoUsuario };
      });
   }
   eventFilter() {
      this.entities = this.info_user.entities
         .map(({ id, nombre }: Entity) => {
            return { id, nombre: nombre[0].toUpperCase() + nombre.slice(1).toLowerCase() };
         })
         .filter((x) => x?.nombre.includes(this.filterEntity));
   }
   loadUser(entity: Entity, redirect: boolean = true) {
      const payload = this.info_user.payload;
      const { usuario, funcionalidades, rol } = payload.find((x) => x?.entidadDTO.id === entity.id);
      const user: User = { ...usuario, funcionalidades, rol };

      localStorage.setItem('payload', btoa(encodeURIComponent(JSON.stringify(user))));
      this.authService.setUserDetails();
      if (redirect) {
         this.redirectUser();
      }
   }
   redirectUser = () => this.router.navigate(['app']);
}

import { Directive, Input, Renderer2, ElementRef, HostListener } from '@angular/core';
import { Sort } from '../functions/sort';

@Directive({
   selector: '[appSortTable]',
})
export class SortTableDirective {
   @Input() appSortTable: Array<any>;
   @Input('column') column: string;
   @Input('sortable') sortable: boolean;
   constructor(private renderer: Renderer2, private targetElem: ElementRef) {}

   @HostListener('click')
   sortData() {
      if (this.sortable !== false) {
         const sort = new Sort();
         const elem = this.targetElem.nativeElement;
         const elemSpan = elem.querySelector('span.sigmi-sort');
         const classList = elemSpan?.classList;
         if (classList) {
            const { length, value, __proto__, ...classArray } = classList;
            const classPost =
               Object.keys(classArray).some((x) => classArray[x] === 'sort-unset') ||
               Object.keys(classArray).some((x) => classArray[x] === 'sort-down')
                  ? 'sort-up'
                  : 'sort-down';
            const classCurrent = Object.keys(classArray).some((x) => classArray[x] === 'sort-unset')
               ? 'sort-unset'
               : Object.keys(classArray).some((x) => classArray[x] === 'sort-up')
               ? 'sort-up'
               : 'sort-down';
            this.renderer.removeClass(elemSpan, classCurrent);

            const preStateUp = elem.parentElement.querySelector('span.sort-up');
            if (preStateUp) {
               this.renderer.removeClass(preStateUp, 'sort-up');
               this.renderer.addClass(preStateUp, 'sort-unset');
            }
            const preStateDown = elem.parentElement.querySelector('span.sort-down');
            if (preStateDown) {
               this.renderer.removeClass(preStateDown, 'sort-down');
               this.renderer.addClass(preStateDown, 'sort-unset');
            }

            this.renderer.addClass(elemSpan, classPost);

            const order = classPost === 'sort-up' ? 'asc' : 'desc';
            this.appSortTable.sort(sort.startSort(this.column, order));
         }
      }
   }
}

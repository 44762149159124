import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {urlsPrivate} from "../../environments/url-services";
import {Observable} from "rxjs";
import {Ichange} from "../site/password-reset/password-reset-end/ichange";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetFinishService {

  constructor(private http: HttpClient) {}

  change(key: string, newPassword: string): Observable<{}> {
    var data: Ichange = {
      key: key,
      newPassword: newPassword,
    };
    return this.http.post(`${environment.server_api_url}${urlsPrivate.reset_end}`, data);
  }

}



import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { SiteModule } from './site/site.module';

const routes: Routes = [
   { path: 'inicio', redirectTo: '', pathMatch: 'full' },
   { path: '', loadChildren: () => import('./site/site.module').then((x) => x.SiteModule) },
   // { path: 'app', loadChildren: () => import('./site-private/site-private.module').then((x) => x.SitePrivateModule), canActivate: [AuthGuard] },
   { path: 'app', loadChildren: () => import('./site-private/site-private.module').then((x) => x.SitePrivateModule) },
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }), SiteModule],
   exports: [RouterModule],
})
export class AppRoutingModule {}

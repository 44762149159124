export class AuthModel {
   nbf: number;
   exp: number;
   iss: string;
   aud: string;
   nonce: string;
   iat: number;
   at_hash: string;
   s_hash: Array<string>;
   sid: string;
   sub: string;
   auth_time: number;
   idp: string;
   acr: string;
   name: string;
   Identificacion: string;
   TipoIdentificacion: string;
   LOA: string;
   PrimerNombre: string;
   SegundoNombre: string;
   PrimerApellido: string;
   SegundoApellido: string;
   nickname: string;
   Telefono: string;
   Direccion: string;
   DireccionJSON: string;
   preferred_username: string;
   email: string;
   email_verified: string;
   given_name: string;
   amr: Array<string>;

   constructor(...args: Partial<AuthModel>[]) {
      Object.assign(this, InitialAuth, ...args);
   }
}
const InitialAuth: Partial<AuthModel> = {
   nbf: 0,
   exp: 0,
   iss: '',
   aud: '',
   nonce: '',
   iat: 0,
   at_hash: '',
   s_hash: [],
   sid: '',
   sub: '',
   auth_time: 0,
   idp: '',
   acr: '',
   name: '',
   Identificacion: '',
   TipoIdentificacion: '',
   LOA: '',
   PrimerNombre: '',
   SegundoNombre: '',
   PrimerApellido: '',
   SegundoApellido: '',
   nickname: '',
   Telefono: '',
   Direccion: '',
   DireccionJSON: '',
   preferred_username: '',
   email: '',
   email_verified: '',
   given_name: '',
   amr: [],
};

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, NEVER, of, Subject, Subscription } from 'rxjs';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import * as fromModels from 'src/app/shared/models';
import { mesaFunctions } from '../functions/mesa-functions.component';
import { browserRefresh } from '../../../app.component';
@Component({
   selector: 'app-mesa-home',
   templateUrl: './mesa-home.component.html',
   styleUrls: ['./mesa-home.component.scss'],
})
export class MesaHomeComponent implements OnInit {
   private unsubscribe$ = new Subject<void>();
   subcriptions: Subscription = new Subscription();
   listaEntidades: any;
   listaConsultores: any;
   listaServiciosDeLaMesa: any;
   modelTable: fromModels.ModelTable;
   displayKey = 'value';
   isDataList = true;
   styleGuide = { caretClass: 'caret', selectBoxClass: 'selectpicker ', selectMenuClass: 'form-control', optionsClass: 'option' };

   //-- Formulario
   isResult: number = 0;
   consultTableinterForm: FormGroup;
   requestData: any;
   responseData: any = [];
   consultantSelected: number;
   estadosServicioMesa: any;
   entidadesmesaId: any = null;
   ordenSelected?: number = null;
   habilitarTabla: boolean;
   displayTable: boolean;
   entidadfinderData: any;
   page: number;
   servicioMesaId: any = null;

   constructor(private activeRouter: ActivatedRoute, private router: Router, private MesaFunctions: mesaFunctions) {}

   ngOnInit(): void {
      if (browserRefresh) {
         this.MesaFunctions.delete_localstorage('dataTblInter');
      }

      localStorage.setItem('active', '1');
      this.loadForm();

      this.subcriptions.add(
         this.activeRouter.queryParams
            .pipe(
               switchMap(({ tblInterConsult }: any) => {
                  if (tblInterConsult) {
                     const dataTable = this.MesaFunctions.get_localstorage('dataTblInter');
                     if (dataTable) {
                        const { page, filter, listaServiciosDeLaMesa, estadosServicioMesa } = dataTable;
                        this.entidadesmesaId = filter?.admEntidadLider;
                        this.servicioMesaId = filter?.servicioMesa;
                        this.loadForm(filter);
                        this.listaServiciosDeLaMesa = listaServiciosDeLaMesa;
                        this.estadosServicioMesa = estadosServicioMesa;
                        this.page = page;
                        this.onSubmitConsultTableinter(1);
                     }

                     return of({});
                  } else {
                     return of({});
                  }
               }),
               mergeMap(() =>
                  forkJoin({
                     listaServiciosDeLaMesa: this.MesaFunctions.getDataPublic('servicios'),
                     estadosServicioMesa: this.MesaFunctions.getDataPublic('estados-servicio'),
                     listadoUsuarios: this.MesaFunctions.getDataPublic('usuarios/mintic'),
                  })
               )
            )
            .subscribe((result: any) => {
               const { listaServiciosDeLaMesa, estadosServicioMesa, listadoUsuarios } = result;
               this.listaServiciosDeLaMesa = listaServiciosDeLaMesa.map(({ servicioId, nombre }: any) => {
                  return { servicioId, nombre };
               });
               this.estadosServicioMesa = estadosServicioMesa?.map(({ estadoServicioId, nombre }: any) => {
                  return { estadoServicioId, nombre };
               });
               this.listaConsultores = listadoUsuarios?.map(({ id, nombre, email }: any) => {
                  return { id, nombre, email };
               });
            })
      );
   }

   ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.subcriptions.unsubscribe();
   }

   onChangeOrdenSelected(dato: number) {
      this.ordenSelected = dato;
   }

   onSubmitConsultTableinter(data: any): void {
      if (data !== 1) {
         this.page = 1;
      }

      var reqData: any = {
         nombre: this.consultTableinterForm.get('nombre').value,
         estado: this.consultTableinterForm.get('estado').value,
         admEntidadLider: this.entidadesmesaId,
         admUsuarioId: this.consultTableinterForm.get('admUsuarioId').value,
         servicioMesa: this.servicioMesaId,
         estadoServicio: this.consultTableinterForm.get('estadoServicio').value,
      };
      this.MesaFunctions.postPublicData('mesaInteroperabilidad/listar', reqData)
         .pipe(takeUntil(this.unsubscribe$))
         .subscribe((result: any) => {
            this.isResult = 1;
            const { mesasInteroperabilidad } = result;
            if (mesasInteroperabilidad.length > 0) {
               this.displayTable = true;
               this.responseData = mesasInteroperabilidad.map(({ estadoMesa, ...data }: any) => {
                  return {
                     estadoMesa: estadoMesa ? 'Abierta' : 'Cerrada',
                     accion: 'VER MESA',
                     ...data,
                  };
               });
               this.loadTable(this.responseData, this.page);
               this.habilitarTabla = true;
            } else {
               this.displayTable = false;
            }
         });
   }

   limpiarConsultTableinterForm(): void {
      this.consultTableinterForm.reset();
      this.entidadesmesaId = null;
      this.servicioMesaId = null;
      this.isResult = 0;
   }

   // Tabla
   loadTable(data: any[], page: number) {
      this.modelTable = new fromModels.ModelTable({
         columns: [
            {
               prop: 'estadoMesa',
               name: 'Estado de la mesa',
               cellClass: 'align-middle',
            },
            {
               prop: 'nombreMesa',
               name: 'Nombre de la mesa',
               cellClass: 'columnText',
            },
            {
               prop: 'razonSocial',
               name: 'Entidad líder',
               minWidth: 300,
               maxWidth: 400,
               cellClass: 'columnText',
            },
            {
               prop: 'nombreCompleto',
               name: 'Consultor de apoyo principal',
               minWidth: 150,
               maxWidth: 250,
               cellClass: 'columnText',
            },
            {
               prop: 'cantidadServicios',
               name: 'No. de servicios',
               minWidth: 80,
               maxWidth: 100,
               cellClass: 'columnNumbers',
            },
            {
               prop: 'accion',
               name: 'Acción',
               minWidth: 80,
               maxWidth: 100,
               sortable: false,
            },
         ],
         rows: data,
         filter: false,
         optionsNumber: [5, 10, 25, 50],
         border: false,
         tblGeneral: true,
         title: { text: 'Resultados de consulta' },
         columnsState: [
            {
               key: 'estadoMesa',
               classKey: [
                  { key: 'Abierta', value: 'state-active' },
                  { key: 'Cerrada', value: 'state-inactive' },
               ],
               filter: false,
            },
         ],
         page: page,
      });
   }

   receiveEvent({ key, data, page }: fromModels.dataEmitter) {
      if (key === 'VER MESA') {
         this.MesaFunctions.add_localstorage('dataTblInter', {
            page,
            filter: this.consultTableinterForm.value,
            listaServiciosDeLaMesa: this.listaServiciosDeLaMesa,
            estadosServicioMesa: this.estadosServicioMesa,
         });
         this.router.navigate([`mesas-interoperabilidad/consult/${data.id}`]);
      }
   }

   loadForm(data?: any) {
      this.consultTableinterForm = new FormGroup({
         nombre: new FormControl(data?.nombre),
         estado: new FormControl(data?.estado),
         admEntidadLider: new FormControl(data?.admEntidadLider),
         consultorPrincipalDeApoyo: new FormControl(data?.consultorPrincipalDeApoyo),
         admUsuarioId: new FormControl(data?.admUsuarioId),
         servicioMesa: new FormControl(data?.servicioMesa),
         estadoServicio: new FormControl(data?.estadoServicio),
      });
   }

   onChangeSearch(type?: any) {
      var requestData = {
         nombreEntidad: type,
      };
      this.entidadesmesaId = type;
      this.MesaFunctions.postPublicData('entidad/finderTopFive', requestData)
         .toPromise()
         .then((responseData: any) => {
            this.entidadfinderData = responseData;
         });
   }

   clearSearch() {
      this.entidadfinderData = [];
      this.entidadesmesaId = null;
      this.consultTableinterForm.get('admEntidadLider').setValue(null);
   }

   changeEntidadesMesa(dato: any) {
      this.entidadesmesaId = dato?.nombre;
   }

   cambiarServicio(data: any) {
      this.servicioMesaId = data?.nombre;
   }

   onChangeSearchServicios(type?: any) {
      const keysValue = {
         keyword: type,
      };
      this.servicioMesaId = type;
      this.MesaFunctions.getDataPublic('servicios/finderTopFive', keysValue)
         .toPromise()
         .then((result: any) => {
            if (result?.length > 0) {
               this.listaServiciosDeLaMesa = result;
            }
         })
         .catch(console.error);
   }

   clearSearchServicios() {
      this.listaServiciosDeLaMesa = [];
      this.servicioMesaId = null;
      this.consultTableinterForm.get('servicioMesa').setValue(null);
   }
}

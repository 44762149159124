import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { urlsPrivate } from 'src/environments/url-services';
import { SharedService } from '../shared/services/shared.services';

@Injectable({
   providedIn: 'root',
})
export class LevelservicesService extends SharedService {
   url: string = environment.server_api_url;

   @Output() dataLoaded = new EventEmitter<any>();
   @Output() dataLoadedEdit = new EventEmitter<any>();
   dataLevelService: any;
   dataLevelServiceEdit: any;
   dataLevelServiceBtn: any;
   serviceConsult: boolean;
   entidadConsulta: boolean;

   constructor(http: HttpClient) {
      super(http);
   }
   consultaServicio: any;
   setdataConsulta = (data: any) => (this.consultaServicio = data);
   getdataConsulta = () => this.consultaServicio;
   deletedataConsulta = () => (this.consultaServicio = undefined);

   setdata = (data: any) => (this.dataLevelService = data);
   getdata = () => this.dataLevelService;
   deletedata = () => (this.dataLevelService = undefined);

   setdataConsultService = (serviceConsult: boolean) => (this.serviceConsult = serviceConsult);
   getdataConsultService = () => this.serviceConsult;
   deletedataConsultService = () => (this.serviceConsult = undefined);
   
   setdataConsultaEntidad = (entidadConsulta: boolean) => (this.entidadConsulta = entidadConsulta);
   getdataConsultaEntidad  = () => this.entidadConsulta;
   deletedataConsultaEntidad  = () => (this.entidadConsulta = undefined);

   setdataEdit = (data: any) => (this.dataLevelServiceEdit = data);
   getdataEdit = () => this.dataLevelServiceEdit;
   deletedataEdit = () => (this.dataLevelServiceEdit = undefined);

   getIdType(id: number, type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}`);
   }
   getConsult = (item: any, nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPrivate[nameService]}`, { params: item });
   getConsultType = (item: any, nameService: string, type: string): Observable<any> =>
      this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${type}`, { params: item });
   updateService(body: any, id: number, type: string, nameService: string): Observable<any> {
      return this.http.put(`${this.url}${urlsPrivate[nameService]}${id}/${type}`, body);
   }
}

export class User {
   id: number;
   tipoIdentificacionId: number;
   numeroIdentificacion: string;
   primerNombre: string;
   segundoNombre: string;
   primerApellido: string;
   segundoApellido: string;
   entidadId: number;
   cargo: string;
   numeroCelular: number;
   otroNumeroTelefonico: number;
   correoEletronico: string;
   usuario: string;
   rol: Rol = new Rol();
   funcionalidades: Array<Funcionalidades> = [];
}

export class Consultor {
   id: number;
   tipoIdentificacionId: number;
   tipoIdentificacion?: string;
   numeroIdentificacion: string;
   primerNombre: string;
   segundoNombre: string;
   primerApellido: string;
   segundoApellido: string;
   entidadId: number;
   cargo: string;
   celular: number;
   otroTelefono: number;
   email: string;
   usuario: string;
   rol: Rol = new Rol();
   funcionalidades: Array<Funcionalidades> = [];
}

export class Rol {
   id: number;
   nombre: string;
}

export class Funcionalidades {
   id: number;
   name: string;
   url: string;
   subFuncionalidades: Array<SubFuncionalidades> = [];
}

export class SubFuncionalidades {
   id: number;
   nombre: string;
   nombreFuncionalidad: string;
   url: string;
   idPadre: number;
   consulta:boolean;
   descripcion: string;
   listaItems?:any;
}

export class Reuniones {
   fecha: string;
   idReunion: string;
   tematica: string;
   consultoApoyo: string;
}

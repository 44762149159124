import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromModels from 'src/app/shared/models';
import { mesaFunctions } from '../functions/mesa-functions.component';

@Component({
  selector: 'app-mesa-consult',
  templateUrl: './mesa-consult.component.html',
  styleUrls: ['./mesa-consult.component.scss'],
})

export class MesaConsultComponent implements OnInit {
  subcriptions: Subscription = new Subscription();
  private unsubscribe$ = new Subject<void>();
  listaConsultores: any;
  active = localStorage.getItem('active') ? parseInt(localStorage.getItem('active')) : 4;
  //- Flags Entidades
  habilitarMenu: boolean = false;
  estadoTxt: string = 'inactive';
  habilitarTabla: boolean = true;
  noResultados: boolean = true;

  //- Flags Servicios Mesa
  habilitarMenuServicio: boolean = false;
  estadoTxtServicio: string = 'inactive';

  habilitarTablaEntidades: boolean = true;
  habilitarTablaServicios: boolean = true;
  habilitarTablaReuniones: boolean = true;

  /* Tabla */
  page: number;
  pageSize: number = 5;
  public pagina = '1';
  filtroColumna: string;
  datosInput: string;
  ordenNacional: string[];
  ordenTerritorial: string[];

  //Select cantidad
  optionsNumber = [5, 10, 20, 50];

  //Opciones filtro
  optionsFilter = ['Nombre entidad', 'Departamento', 'Municipio'];
  optionsFilterServices = ['Estado del servicio', 'Nombre del servicio'];

  /* request Data */
  entidades: string = '';
  otraEntidad: string = '';
  servicios: string = '';
  otroServicio: string = '';
  serviciosMesa: any;
  serviciosMesaOriginal: any;
  serviciosMesaTabla: any;
  estadosServicioMesa: any;
  entidadesconsultores: any;
  entidadescorreoconsultor: any;
  entidadesmesaId: any;
  correoentidadesmesa: any;
  activoServicio: boolean = false;
  radioActivoServicio: boolean = false;

  // Info mesa
  idMesaInteroperabilidad: number;

  // Tabla entidades
  modelTableEntities: fromModels.ModelTable;
  datosTablaEntidades: any[];

  // Tabla servicios
  modelTableServices: fromModels.ModelTable;
  datosTablaServicios: any[];

  // Tabla reuniones
  modelTableMeeting: fromModels.ModelTable;
  requestDataReuniones: any = {};
  datosTablaReuniones: any;
  verMesaId: any;
  requestDataVerMesas: { id: number };
  idRequestMesa: number;

  constructor(
    private router: Router, 
    private activeRouter: ActivatedRoute, 
    private MesaFunctions: mesaFunctions
  ) { }

  ngOnInit(): void {
    this.idRequestMesa = parseInt(this.activeRouter.snapshot.paramMap.get('id'));

    //Información de la mesa
    this.MesaFunctions.postPublicData('mesaInteroperabilidad/listarMesaId', { id: this.idRequestMesa })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const { reunion } = result;
        const { id } = reunion;
        this.idMesaInteroperabilidad = id;

        //Entidades participantes
        this.MesaFunctions.getDataPublic('mdi-minteroperabilidad-entidads-mesa/' + id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((responseData: any) => {
            this.datosTablaEntidades = responseData.map(({ ...data }: any) => {
              return { ...data };
            });
            this.loadTableEntities(this.datosTablaEntidades);
            if (this.datosTablaEntidades.length > 0) {
              this.habilitarTablaEntidades = true;
            } else {
              this.habilitarTablaEntidades = false;
            }
          });

        //Servicios de la mesa
        this.MesaFunctions.getDataPublic('mesaInteroperabilidad/servicios-mesa/mesa/' + id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((responseData: any) => {
            this.datosTablaServicios = responseData.map(({ ...data }: any) => {
              return { ...data };
            });
            this.loadTableServices(this.datosTablaServicios);
            if (this.datosTablaServicios.length > 0) {
              this.habilitarTablaServicios = true;
            } else {
              this.habilitarTablaServicios = false;
            }
          });

        //Reuniones de avance
        this.MesaFunctions.postPublicData('mesaInteroperabilidad/listarReunionMesa', { id: id })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((responseData: any) => {
            const { Reuniones } = responseData;
            this.datosTablaReuniones = Reuniones.map(({ fecha, ...data }: any) => {
              fecha = fecha.split('T')[0];
              return {
                fecha: fecha,
                accion: 'VER',
                ...data,
              };
            });

            this.loadTableMeeting(this.datosTablaReuniones);
            if (this.datosTablaReuniones.length > 0) {
              this.habilitarTablaReuniones = true;
            } else {
              this.habilitarTablaReuniones = false;
            }
          });
        this.verMesaId = reunion;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subcriptions.unsubscribe();
  }

  volverConsultarMesa() {
    this.router.navigate(['mesas-interoperabilidad/home'], {
      queryParams: { tblInterConsult: 1 },
    });
  }

  guardarTabActual(currentTab: string): void {
    localStorage.setItem('active', currentTab);
  }

  // Tabla entidades
  loadTableEntities(data: any[]) {
    this.modelTableEntities = new fromModels.ModelTable({
      columns: [
        {
          prop: 'entidad',
          name: 'Nombre Entidad',
          cellClass: 'columnText',
        },
        {
          prop: 'admOrdenName',
          name: 'Orden',
          sortable: false,
          cellClass: 'columnText',
        },
        {
          prop: 'admDepartamentoName',
          name: 'Departamento',
          minWidth: 200,
          maxWidth: 400,
          cellClass: 'columnText',
        },
        {
          prop: 'admMunicipioName',
          name: 'Municipio',
          minWidth: 200,
          maxWidth: 400,
          cellClass: 'columnText',
        },
      ],
      rows: data,
      filter: true,
      optionsSelect: [
        { display: 'Nombre Entidad', value: 'entidad' },
        { display: 'Departamento', value: 'admDepartamentoName' },
        { display: 'Municipio', value: 'admMunicipioName' },
      ],
      columnsState: [
        {
          key: 'admOrdenName',
          classKey: [
            { key: 'Territorial', value: '' },
            { key: 'Nacional', value: '' },
          ],
          filter: true,
          selections: ['Territorial', 'Nacional'],
        },
      ],
      optionsNumber: [10, 25, 50],
      border: false,
      messageEmpty: { message: 'Aún no hay entidades participantes registradas.' },
    });
  }

  // Tabla servicios
  loadTableServices(data: any[]) {
    this.modelTableServices = new fromModels.ModelTable({
      columns: [
        {
          prop: 'estado',
          name: 'Estado del servicio',
          minWidth: 300,
          maxWidth: 400,
          cellClass: 'columnText',
        },
        {
          prop: 'nombre',
          name: 'Nombre del servicio',
          cellClass: 'columnText',
        },
      ],
      rows: data,
      filter: true,
      optionsSelect: [
        { display: 'Estado del servicio', value: 'estado' },
        { display: 'Nombre del servicio', value: 'nombre' },
      ],
      optionsNumber: [10, 25, 50],
      border: false,
      columnsState: [
        {
          key: 'estado',
          classKey: [
            { key: 'Identificado', value: 'state-gray' },
            { key: 'Estandarizado Nivel 1', value: 'state-yellow' },
            { key: 'Evaluado Nivel 2', value: 'state-purple' },
            { key: 'Publicado Nivel 3', value: 'state-green' },
            { key: 'Identificado no estandarizado', value: 'state-gray' },
          ],
          filter: false,
        },
      ],
      messageEmpty: { message: 'Aún no hay servicios de la mesa registrados.' },
    });
  }

  //- Tabla Reuniones de avance
  loadTableMeeting(data) {
    this.modelTableMeeting = new fromModels.ModelTable({
      columns: [
        {
          prop: 'tematica',
          name: 'Temática',
          cellClass: 'columnText',
        },
        {
          prop: 'fecha',
          name: 'Fecha',
          minWidth: 150,
          maxWidth: 200,
          cellClass: 'columnText',
        },
        {
          prop: 'consultoApoyo',
          name: 'Consultores de apoyo',
          sortable: false,
          cellClass: 'columnText',
        },
        {
          prop: 'accion',
          name: 'Acción',
          minWidth: 80,
          maxWidth: 100,
          sortable: false,
          cellClass: 'align-middle',
        },
      ],
      rows: data,
      filter: true,
      optionsSelect: [
        { display: 'Temática', value: 'tematica' },
        { display: 'Fecha', value: 'fecha' },
      ],
      optionsNumber: [10, 25, 50],
      border: false,
      messageEmpty: { message: 'Aún no hay reuniones de avance registradas.' },
    });
  }

  receiveEventMeeting({ key, data }: fromModels.dataEmitter) {
    const { idReunion } = data;
    if (key === 'VER') {
      this.router.navigate([`mesas-interoperabilidad/viewmeeting/${idReunion}/${this.idMesaInteroperabilidad}`], {
        queryParams: { urlOri: 'consultar' },
      });
    }
  }
}
export enum urlsPublic {
   login = '/api/servicioLogin/login',
   registrar = '/api/registrar',
   get_tipo_identificacion = '/api/adm-tipo-identificacions',
   listarEntidades = '/api/adm-entidads',
   icon = '<img src="./../../../assets/images/icon.png">',
   types_element_data = '/api-public/ntn-tipo-elementos/',
   types_of_data = '/api-public/ntn-tipo-datos',
   wear_layer = '/api-public/servicioCapa/',
   get_dictionary = '/api-public/elemento-dato/',
   servicio_finderTopFive = '/api-public/servicios/finderTopFive',
   entidad_finderTopFiveProveeNivelTres = '/api-public/entidad/finderTopFiveProveeNivelTres',
   sector_finderTopFive = '/api-public/sector/finderTopFive',
   servicios = '/api-public/servicios/',
   catalogs_elements_service = '/api-public/catalogo-elemento/servicio/',
   reports_xsd = '/api-public/schema/',
   save_options_levelone = '/api-public/solicitud/',


}

export enum urlsPrivate {
   get_tipo_identificacion = '/api/adm-tipo-identificacions',
   contenidologsfiltro = '/api/servicioauditoria/findByFilters',
   listarActividades = '/api/servicioHome/listarActividades',
   actividadAsignadaById = '/api/servicioHome/listarActividadesById',
   assignResponsible = '/api/servicioHome/listarActividadesUpdate',
   validarusuario = '/api/validar/usuario/',
   getadmroles = '/api/adm-rols',
   getentidad = '/api/adm-entidads',
   getadmentidad_MINTIC = '/api/adm-entidads?sigla.equals=MINTIC',
   postaprobarusuario = '/api/validar/aprobarUsuario',
   postrechazarusuario = '/api/validar/rechazarUsuario',
   reset_init = '/api/cuenta/reset-password/init',
   reset_end = '/api/cuenta/reset-password/finish',
   entidad_search = '/api/entidad/search',
   entidad_finderTopFive = '/api/entidad/finderTopFive',
   entidad_finderTopFivePublic = '/api/entidad/finderTopFivePublic',
   entidad_finderTopFiveIES = '/api/entidad/finderTopFiveIES',
   entidad_finderTopFiveProvee = '/api/entidad/finderTopFiveProvee',
   adm_subordens = '/api/adm-subordens',
   adm_departamentos = '/api/adm-departamentos',
   adm_municipios = '/api/adm-municipios',
   adm_sectors = '/api/adm-sectors',
   entidad_detail = '/api/entidad/', //Revisarlo
   entidad_servicios = '/api/entidad/servicios',
   entidad_mesas = '/api/admin/entidad/mesas', //Revisarlo
   capaCrear = '/api/servicioCapa/capaCrear',
   capaFiltro = '/api/servicioCapa/capaFiltro',
   capaById = '/api/servicioCapa/capaById',
   capaActualizar = '/api/servicioCapa/capaActualizar',
   layer_version = '/api/servicioCapa/version/',
   layer_versionate = '/api/servicioCapa/versionar',
   usage_layer = '/api/servicioCapa/',
   entities_participants = '/api/mdi-minteroperabilidad-entidads-mesa/',
   add_entities = '/api/mdi-minteroperabilidad-entidads/',
   delete_entities = '/api/mdi-minteroperabilidad-entidads/',
   mesaInteroperabilidad = '/api/mesaInteroperabilidad',
   servicios_de_la_mesa = '/api/servicios',
   estados_servicio_mesa = '/api/estados-servicio',
   listarConsultores = '/api/mesaInteroperabilidad/crear',
   crearmesa = '/api/mesaInteroperabilidad/crear',
   crearreunion = '/api/mesaInteroperabilidad/crearReunionMesa',
   uploadfile = '/api/mesaInteroperabilidad/uploadFile',
   edit_meeting = '/api/mesaInteroperabilidad/editarReunionMesa',
   servicios_mesa_masivo = '/api/mesaInteroperabilidad/servicios-mesa/mesa/',
   add_services = '/api/mesaInteroperabilidad/servicio-mesa',
   delete_services = '/api/mesaInteroperabilidad/servicio-mesa/',
   post_users = '/api/getUsuariosByFilter',
   associated_users = '/api/usuariosConAsociados/',
   put_user = '/api/cambiarEstado',
   list_meetings = '/api/mesaInteroperabilidad/listarReunionMesa',
   meeting_view_id = '/api/mesaInteroperabilidad/listarReunionMesaId',
   consult_table_inter = '/api/mesaInteroperabilidad/listar',
   edit_table_inter = '/api/mesaInteroperabilidad/editar',
   list_table_inter = '/api/mesaInteroperabilidad/listarMesaId',
   mesas_entidad = '/api/entidad/mesas/',
   get_solicitud = '/api/solicitud/nivel-uno/',
   get_mesas = '/api/mesasInteroperabilidad',
   get_standardization = '/api/dato-estandarizar/',
   users_mintic = '/api/usuarios/mintic',
   get_user_id = '/api/adm-usuario-views/',
   save_entities_levelone = '/api/solicitud/nivel-uno/',
   data_standard_services = '/api/servicio/',
   types_of_data = '/api/ntn-tipo-dato/',
   area_responsables = '/api/ntn-area-responsables',
   tipo_solicituds = '/api/ntn-tipo-solicituds',
   save_options_levelone = '/api/solicitud/',
   upload_file_opt = '/api/uploadFileOpt',
   download_file_opt = '/api/downloadFileOpt/',
   download_file_opt_public = '/api-public/downloadFileOpt/',
   get_dictionary = '/api/elemento-dato/',
   get_dictionary_version = '/api/elemento-dato/versionar',
   get_identificadores = '/api/elemento-dato/identificadores',
   get_identificadores_onto = '/api/elemento-dato/identificadores-ontologia',
   get_nombres = '/api/elemento-dato/nombres',
   element_layer = '/api/elemento-dato/capa-uso/',
   get_associated = '/api/elemento-dato/associatedElement/',
   set_association = '/api/elemento-dato/association/',
   wear_layer = '/api/servicioCapa/',
   types_element_data = '/api/ntn-tipo-elementos/',
   delete_file = '/api/deleteFileOpt/',
   solicitud_sendEmail = '/api/solicitud/sendEmail',
   get_actividades = '/api/usuario/',
   actividad_responsable = '/api/actividad-asignada/',
   servicio_finderTopFive = '/api/servicios/finderTopFive',
   get_solicitudes = '/api/solicitudes/finderTopFive',
   servicioCapa_finderTopFive = '/api/servicioCapa/finderTopFive',
   get_detalle_solicitante= '/api/usuarios/detalle-solicitante',
   get_solicitantesfinderTopFive = '/api/usuarios/solicitantes/finderTopFive',
   get_servicios_notificados = '/api/servicios/notificados/finderTopFive',
   get_servicios = '/api/servicios/finderTopFive',
   search_solicitudes = '/api/solicitudes',
   catalogs_elements = '/api/catalogo-elemento/',
   catalogs_elements_service = '/api/catalogo-elemento/servicio/',
   estados_servicios = '/api/ntn-estado-servicios',
   estados_solicitud = '/api/ntn-estados',
   edit_leveltwo = '/api/solicitud/nivel-dos',
   options_leveltwo = '/api/solicitud/',
   get_solicitud_leveltwo = '/api/solicitud/nivel-dos/',
   get_notificacion_levelone = '/api/solicitud/',
   ontology_rules = '/api/ontologia/regla/',
   ontology_events = '/api/ontologia/evento/',
   ontology_individuals = '/api/ontologia/individuo/',
   ontology_definitions = '/api/ontologia/definicion/',
   create_solicitud_levelthree = '/api/solicitud/nivel-tres/',
   get_solicitud_levelthree = '/api/solicitud/nivel-tres/',
   put_solicitud_levelthree = '/api/solicitud/nivel-tres/',
   ontology_all = '/api/ontologia/',
   levelthree_solicitud = '/api/solicitud/',
   levelthree_servicio = '/api/servicio/',
   levelthree_entidad = '/api/entidad/',
   get_estados_nivel = '/api/ntn-estados-nivel',
   get_consultar_sistemas = '/api/ntn-tecnico-sistemas',
   get_consultar_lenguajes = '/api/ntn-tecnico-lenguajes',
   get_consultar_basedatos = '/api/ntn-tecnico-basedatos',
   post_report_element = '/api/report/elementoDatoByFilter',
   post_report_request = '/api/report/solicitudServicioByFilter',
   post_report_indicators = '/api/report/indicadoresByFilter',
   reports_xroad = '/api/reports/xroad',
   reports_govco = '/api/reports/tramites-govco',
   reports_validator = '/api/report/validador',
   reports_xsd = '/api/schema/',
   sector_finderTopFive = '/api/sector/finderTopFive',
   services_levelthree = '/api/nivel-tres',
   states_services_levelthree = '/api/estados-servicio/nivel-tres',
   admin_rol= '/api/administrar-roles/',
   parametrization = '/api/parametrizador/',
   parametrization_emails = '/api/parametrizador/correos/',
   change_entity= '/api/cambiarEntidad',
   admin_oid = '/api/oid/',
   parametrization_settings = '/api/parametros/',
   adm_naturalezas = '/api/adm-naturaleza-juridicas',


}

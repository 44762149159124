import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
   providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
   constructor(private authService: AuthService) {}

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
         catchError((err) => {
            if (err.status === 401) {
               this.authService.logout();
            } else if (err.status === 400 && err.error != null) {
               return throwError(err);
            }
            return throwError(err);
         })
      );
   }
}

<!-- container principal -->
<div class="container">
   <div class="row">
      <div class="col-md-10">
         <div class="headline-xl text-left mb-2">Ver Reunión - {{ infoReunion?.tematica }}</div>
         <div class="headline-m mb-4">A continuación puede ver los detalles de la reunión seleccionada</div>
      </div>
   </div>
</div>

<!-- Contenedor principal -->
<div class="container">
   <div class="row mt-5">
      <div class="col-md-10">
         <div class="row-md-6">
            <label class="titleForm">Temática:</label>
            <p class="body-1 ps-3">{{ infoReunion?.tematica }}</p>
         </div>

         <div class="row mt-4">
            <div class="col-md-8">
               <label class="titleForm">Avance</label>
               <p class="body-1">{{ infoReunion?.avance }}</p>
            </div>
         </div>

         <div class="row mt-4">
            <div class="col-md-3">
               <label class="titleForm">Fecha</label>
               <p class="body-1">{{ infoReunion?.fechaCreacion }}</p>
            </div>
            <div class="col-md-4">
               <label class="titleForm">Hora</label>
               <p class="body-1">{{ infoReunion?.hora }}</p>
            </div>
         </div>

         <div class="row mt-4">
            <div class="col-md-4">
               <label class="titleForm">Consultor de apoyo principal</label>
               <p class="body-1">{{ infoReunion?.consultorPrincipal?.nombreUsuario }}</p>
            </div>
         </div>

         <div class="row mt-4">
            <div class="col-md-3">
               <label class="titleForm">Consultores de apoyo</label>
            </div>
         </div>

         <div class="row">
            <div class="col-md-2 mt-2 mb-2" *ngFor="let item of infoReunion?.usersParticipantes">
               <p class="body-1" style="text-transform: capitalize">{{ item.nombreUsuario }}</p>
            </div>
         </div>

         <div class="row mt-4">
            <div class="col-md-6">
               <label class="titleForm">Participantes</label>
               <p class="body-1">{{ infoReunion?.participante }}</p>
            </div>
         </div>

         <div class="row mt-4">
            <div class="col-md-3">
               <label class="titleForm">Documentos</label>
            </div>
         </div>

         <div class="row-md-6 mb-5">
            <div class="col-md-4">
               <app-sigmi-upload [nameCtrl]="'meetingFiles'" [_files]="attachments" [download]="true"> </app-sigmi-upload>
            </div>
         </div>
         
         <div class="row-md-6 text-left pb-5 mb-2">
            <button type="button" class="btn btn-round btn-middle" (click)="regresar()">REGRESAR</button>
         </div>
      </div>
   </div>
</div>

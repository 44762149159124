<app-header-site></app-header-site>
<!-- container -->
<div class="login">
   <div class="container" style="min-height: 550px; padding: 0px; padding-left: 10px">
      <div class="row mt-4">
         <div class="col col-sm-12 col-md-5">
            <div class="headline-xl">Sistema de Información de Gestión del Marco de Interoperabilidad</div>
            <div class="body-1 mt-3">
               Sistema de información que permite gestionar el proceso estandarización de datos y la publicación de servicios de intercambio
               Información, para cumplir con los dominios definidos en el Marco de Interoperabilidad para Gobierno Digital.
            </div>
         </div>
         <div class="col col-md-1"></div>
         <div class="col col-sm-12 col-md-6">
            <div class="cardd">
               <div class="cardd-body">
                  <div class="headline-l mt-3">Inicio de sesión</div>
                  <div class="row mt-4">
                     <div class="col">
                        <p>
                           Para iniciar en el aplicativo SIGMI debe estar registrado en el autenticador digital, si ya está registrado podrá iniciar
                           sesión directamente.
                        </p>
                     </div>
                  </div>
                  <div class="row mt-2">
                     <div class="col">
                        <div class="body-1">
                           ¿Ya tiene cuenta? &nbsp;&nbsp;&nbsp;&nbsp;
                           <a class="BOTON-TEXT" (click)="registerUser()">REGISTRAR NUEVO USUARIO</a>
                        </div>
                     </div>
                  </div>
                  <div class="row mt-5">
                     <div class="col">
                        <button class="btn btn-round btn-high" (click)="enterCitizenFolder('login')">iniciar sesión</button>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col">
                        <p>Recuerde que el restablecimiento de contraseña se debe realizar desde el servicio de Autenticador Digital.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>

<!-- container principal -->
<div class="container">
   <div class="row mb-5 mt-5">
      <div class="col-8">
         <div class="headline-xl">Mesas de Interoperabilidad</div>
         <div class="headline-s mt-4">
            Espacio generado para organizar el esfuerzo de dos o más<br />entidades para realizar intercambio de información.
         </div>
      </div>
   </div>

   <div class="row mt-5 mb-4">
      <div class="col-md-12">
         <form (ngSubmit)="onSubmitConsultTableinter(0)" [formGroup]="consultTableinterForm">
            <div class="row">
               <div class="form-group col-md-6">
                  <label class="body-1 mb-1"> Nombre de la mesa de interoperabilidad </label>
                  <input class="form-control" type="text" placeholder="Ej: pasaportes" formControlName="nombre" />
               </div>

               <div class="form-group col-md-6">
                  <label class="body-1 mb-1"> Estado de la mesa de interoperabilidad </label>
                  <div class="row mb-3 mt-1">
                     <div class="col-md-6">
                        <label class="radiobutton-govco">
                           <input
                              type="radio"
                              formControlName="estado"
                              id="abiertaSelected"
                              [value]="true"
                              (click)="onChangeOrdenSelected($event.target.value)"
                           />
                           <label for="abiertaSelected">Activa</label>
                        </label>
                     </div>

                     <div class="col-md-6">
                        <label class="radiobutton-govco d-block">
                           <input type="radio" formControlName="estado" id="nacionalSelected" [value]="false" />
                           <label for="nacionalSelected"> Cerrada </label>
                        </label>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mb-2">
               <div class="col-md-12">
                  <label for="id" class="body-1 mb-1">Entidad Líder</label>
                  <div class="ng-autocomplete form-item">
                     <ng-autocomplete
                        [data]="entidadfinderData"
                        minQueryLength="3"
                        [searchKeyword]="'nombre'"
                        (selected)="changeEntidadesMesa($event)"
                        (inputChanged)="onChangeSearch($event)"
                        (inputCleared)="clearSearch()"
                        [itemTemplate]="itemTemplate"
                        debounceTime="500"
                        [notFoundTemplate]="notFoundTemplate"
                        placeholder="Buscar entidad"
                        formControlName="admEntidadLider"
                     >
                     </ng-autocomplete>
                     <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.nombre"></a>
                     </ng-template>
                     <ng-template #notFoundTemplate let-notFound> No hay resultados </ng-template>
                  </div>
               </div>
            </div>

            <div class="row mb-2">
               <div class="form-group col-md-6">
                  <label for="id" class="body-1 mb-1"> Consultor principal de apoyo </label>
                  <ng-select
                     [options]="listaConsultores"
                     placeholder="Seleccione"
                     [items]="listaConsultores"
                     [displayKey]="displayKey"
                     bindLabel="nombre"
                     bindValue="id"
                     [isDatalist]="isDataList"
                     [clearable]="true"
                     notFoundText="No hay resultados"
                     formControlName="admUsuarioId"
                  >
                  </ng-select>
               </div>
            </div>
            <div class="row mb-2">
               <div class="form-group col-md-6">
                  <label class="body-1 mb-1">Servicio de la mesa</label>
                  <div class="ng-autocomplete">
                     <ng-autocomplete
                        [data]="listaServiciosDeLaMesa"
                        minQueryLength="3"
                        [searchKeyword]="'nombre'"
                        (selected)="cambiarServicio($event)"
                        (inputChanged)="onChangeSearchServicios($event)"
                        (inputCleared)="clearSearchServicios()"
                        [itemTemplate]="itemTemplate"
                        debounceTime="500"
                        [notFoundTemplate]="notFoundTemplate"
                        placeholder="Buscar servicio"
                        formControlName="servicioMesa"
                     >
                     </ng-autocomplete>
                     <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.nombre"></a>
                     </ng-template>
                     <ng-template #notFoundTemplate let-notFound> No hay resultados </ng-template>
                  </div>
               </div>
               <div class="form-group col-md-6">
                  <label class="body-1 mb-1">Estado del servicio</label>
                  <ng-select
                     [options]="estadosServicioMesa"
                     placeholder="Seleccionar: "
                     [items]="estadosServicioMesa"
                     [displayKey]="displayKey"
                     bindLabel="nombre"
                     [styleGuide]="styleGuide"
                     [isDatalist]="isDataList"
                     [clearable]="true"
                     notFoundText="No hay resultados"
                     formControlName="estadoServicio"
                     bindValue="estadoServicioId"
                  >
                  </ng-select>
               </div>
            </div>

            <div class="row mt-5 mb-5">
               <div class="row-md-6 mt-4">
                  <button class="btn btn-round btn-high" type="submit">Consultar</button>
                  <button type="button" class="btn btn-low limpiar" (click)="limpiarConsultTableinterForm()">LIMPIAR</button>
               </div>
            </div>
         </form>
      </div>
   </div>
</div>

<div class="container-fluid content-table-message" *ngIf="isResult > 0">
   <div class="row">
      <div class="col">
         <div class="container content-table ps-0 pe-0">
            <div class="row">
               <ng-container *ngIf="displayTable; else elseTemplate">
                  <div class="col p-0">
                     <app-sigmi-table [_modelTable]="modelTable" (eventEmitter)="receiveEvent($event)"> </app-sigmi-table>
                  </div>
               </ng-container>
               <ng-template #elseTemplate>
                  <div class="col p-4 ps-4">
                     <h3 class="headline-l ps-4">No hay resultados coincidentes</h3>
                     <br />
                     <p class="ps-4">Mejore sus resultados de búsqueda al:</p>
                     <ul class="ps-5">
                        <li>Quitar o agregar información en los filtros</li>
                        <li>Revisar ortografía</li>
                        <li>Utilizar menos palabras clave</li>
                        <li>Buscar algo menos específico</li>
                     </ul>
                     <br />
                     <p class="ps-4">
                        O remita su consulta a los correos <label class="textLink">soporteccc@mintic.gov.co</label> <br />con copia a
                        <label class="textLink">lenguaje@mintic.gov.co</label>
                     </p>
                  </div>
               </ng-template>
            </div>
         </div>
      </div>
   </div>
</div>

import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SHAREDFUNCTIONS } from 'src/app/shared/functions/functions';

import * as FileSaver from 'file-saver';
import * as fromModels from '../../models';
import { SharedService } from '../../services/shared.services';

@Component({
   selector: 'app-sigmi-upload',
   templateUrl: './sigmi-upload.component.html',
   styleUrls: ['./sigmi-upload.component.scss'],
})
export class SigmiUploadComponent implements OnInit {
   filesUpload: fromModels.ImgModel[] = [];

   inProgress: boolean = false;
   progress: number;
   type: string;

   @Input() title: string;
   @Input() nameCtrl: string;
   @Input() download: boolean = false;
   @Input() multiple: Boolean = false;
   @Input() publico: Boolean = false;
   @Input() accept: string;
   @Input() maximo: any;
   @Input()
   set _files(files: fromModels.ImgModel[]) {
      if (files) {
         this.filesUpload = [];
         this.filesUpload = [...files];
      }
   }

   @Output() uploadComplete = new EventEmitter<fromModels.ImgModel[]>();
   @Output() uploadDelete = new EventEmitter<fromModels.ImgModel[]>();
   constructor(
      private notificacionesService: NotificacionesService,
      private sharedService: SharedService,
      private spinner: NgxSpinnerService,
      @Inject(SHAREDFUNCTIONS) private sf: any
   ) {}

   ngOnInit(): void {}

   @HostListener('dragover', ['$event'])
   @HostListener('dragleave', ['$event'])
   onDrag = (event) => {
      event.preventDefault();
      event.stopPropagation();
   };
   @HostListener('drop', ['$event'])
   onDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;
      if (files.length > 0) {
         if (files.length > 1 && !this.multiple) {
            this.notificacionesService.notificacionError('Numero de Archivos excedido');
            return;
         }
         if (this.multiple && this.maximo) {
            const filesUploadAndNew = files.length + this.filesUpload.length;
            if (filesUploadAndNew > this.maximo) {
               this.notificacionesService.notificacionError('Numero de Archivos excedido. El máximo permitido es: ' + this.maximo + ' archivos');
               return;
            }
         }
         if (this.accept) {
            let typeError = false;
            [...Array(files.length).keys()].forEach((i) => {
               const { name } = files[i];
               const partsName = name.split('.');
               const type = partsName[partsName.length - 1];
               if (!this.accept.includes(type)) {
                  this.notificacionesService.notificacionError('Archivo adjunto no es permitido');
                  typeError = true;
               }
            });
            if (typeError) return;
         }
         if (!this.multiple && this.filesUpload.length > 0) {
            this.deletefile(this.filesUpload[0]);
         }
         this.loadFiles(files);
      }
   };
   onClick = () => {
      const fileUpload = document.getElementById(this.nameCtrl) as HTMLInputElement;

      fileUpload.onchange = () => {
         if (fileUpload.files.length > 0) {
            if (!this.multiple && this.filesUpload.length > 0) {
               this.deletefile(this.filesUpload[0]);
            }
            if (this.multiple && this.maximo) {
               const filesUploadAndNew = fileUpload.files.length + this.filesUpload.length;
               if (filesUploadAndNew > this.maximo) {
                  this.notificacionesService.notificacionError('Numero de Archivos excedido. El máximo permitido es: ' + this.maximo + ' archivos');
                  return;
               }
            }
            if (this.accept) {
               let typeError = false;
               [...Array(fileUpload.files.length).keys()].forEach((i) => {
                  const { name } = fileUpload.files[i];
                  const partsName = name.split('.');
                  const type = partsName[partsName.length - 1];
                  if (!this.accept.includes(type)) {
                     this.notificacionesService.notificacionError('Archivo adjunto no es permitido');
                     typeError = true;
                  }
               });
               if (typeError) return;
            }
            this.loadFiles(fileUpload.files);
         }
      };
      fileUpload.click();
   };
   loadFiles = ({ length, ...archives }) => {
      [...Array(length).keys()].forEach((i) => {
         const { name, size } = archives[i];
         if (Math.ceil(size / 1000) < 51181) {
            const intervalGeneral = setInterval(() => {
               this.getBase64(archives[i]).then((fileUploadResponse) => {
                  const dataFile = fileUploadResponse.toString().split(';base64,');
                  const type = dataFile[0].replace('data:', '');
                  const file64 = dataFile[1];
                  this.filesUpload.push({
                     nameCtrl: this.nameCtrl,
                     name,
                     file: archives[i],
                     fileBase64: file64,
                     size: Math.ceil(size / 1000),
                     type,
                  });
                  this.uploadComplete.emit(this.filesUpload);
               });
               clearInterval(intervalGeneral);
            }, 1100 * i);
         } else {
            this.notificacionesService.notificacionError('Archivo excede el peso permitido');
         }
      });
   };
   getBase64 = (file: File) =>
      new Promise((resolve, reject) => {
         this.inProgress = true;
         this.progress = 0;

         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            const interval = setInterval(() => {
               this.progress++;
               if (this.progress === 100) {
                  clearInterval(interval);
                  const fileBase64 = reader.result.toString();
                  this.inProgress = false;
                  return setTimeout(() => resolve(fileBase64), 100);
               }
            }, 10);
         };
         reader.onerror = (error) => reject(error);
      });

   deletefile = (data: any) => {
      this.filesUpload.splice(this.filesUpload.indexOf(data), 1);
      this.uploadDelete.emit(data);
   };
   downloadFile = (data: any) => {
      this.sharedService
         .post({ archivoId: data.id }, 'download_file_opt')
         .toPromise()
         .then((response) => {
            const { mensajeResponse, object } = response;
            if (mensajeResponse.estado) {
               const { nombreArchivo, base64Archivo, tipo } = object;
               const file = this.sf.returnconvertBase64ToBlob(base64Archivo, `data:${tipo}`);
               FileSaver.saveAs(file, nombreArchivo);
            } else {
               this.notificacionesService.notificacionError(mensajeResponse.mensaje);
            }
         })
         .catch((error) => {
            console.error('Promise rejected with ' + JSON.stringify(error));
         });
   };
   downloadFilePublic = (data: any) => {
      this.sharedService
         .post({ archivoId: data.id }, 'download_file_opt_public')
         .toPromise()
         .then((response) => {
            const { mensajeResponse, object } = response;
            if (mensajeResponse.estado) {
               const { nombreArchivo, base64Archivo, tipo } = object;
               const file = this.sf.returnconvertBase64ToBlob(base64Archivo, `data:${tipo}`);
               FileSaver.saveAs(file, nombreArchivo);
            } else {
               this.notificacionesService.notificacionError(mensajeResponse.mensaje);
            }
         })
         .catch((error) => {
            console.error('Promise rejected with ' + JSON.stringify(error));
         });
   };
}

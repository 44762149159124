<ng-container>
   <form [formGroup]="registerForm">
      <div class="row mb-2">
         <div class="col">
            <label for="cargo" class="textoLabel body-1 mb-1">NIT*</label>
            <input
               type="text"
               class="form-control"
               id="Nit privada"
               formControlName="nit"
               placeholder="NIT"
               (keyup)="onKeyUpEventNit($event)"
               [validCtrl]="[
                  { key: 'required', message: 'NIT es requerido' },
                  { key: 'pattern', message: 'Contiene caracteres no validos' }
               ]"
            />
         </div>
         <div class="col">
            <label for="cargo" class="textoLabel body-1 mb-1">Número de verificación NIT*</label>
            <input
               type="text"
               class="form-control"
               id="cargo"
               formControlName="dv"
               placeholder="NIT"
               (keyup)="onKeyUpEventDv($event)"
               [validCtrl]="[
                  { key: 'required', message: 'Número de verificación es requerido' },
                  { key: 'pattern', message: 'Contiene caracteres no validos' }
               ]"
            />
         </div>
      </div>
   </form>
   <div class="row mb-3">
      <div class="col">
         <button class="btn btn-round btn-high" (click)="searchPrivate()" [disabled]="registerForm.invalid">CONSULTAR</button>
      </div>
   </div>
   <div class="col mb-3">
      <ng-container *ngIf="bandPrivate">
         <label class="titleForm my-1">Razon Social </label>

         <div class="body-1">{{ namePrivate }}</div>
      </ng-container>
   </div>
</ng-container>

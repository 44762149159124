import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, ResolveEnd, Router } from '@angular/router';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import * as fromModels from 'src/app/shared/models';
import { mesaFunctions } from '../functions/mesa-functions.component';

@Component({
  selector: 'app-mesa-ver-reunion',
  templateUrl: './mesa-ver-reunion.component.html',
  styleUrls: ['./mesa-ver-reunion.component.scss'],
})

export class MesaVerReunionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  subcriptions: Subscription = new Subscription();
  idMeeting: string;
  infoReunion: any;
  idmesa: any;
  attachments: fromModels.ImgModel[];
  urlRegresar: any;

  constructor(
    private router: Router, 
    private activeRoute: ActivatedRoute, 
    private MesaFunctions: mesaFunctions
  ) { }

  ngOnInit(): void {
    this.idMeeting = this.activeRoute.snapshot.paramMap.get('id');
    this.idmesa = this.activeRoute.snapshot.paramMap.get('idmesa');
    this.attachments = [];
    this.subcriptions
      .add(
        this.activeRoute.queryParams
          .pipe(
            mergeMap(({ urlOri }: Params) =>
              forkJoin({
                urlOri: of(urlOri),
                reunionId: this.MesaFunctions.postPublicData('mesaInteroperabilidad/listarReunionMesaId', { idReunion: this.idMeeting }),
              })
            )
          )
          .subscribe((result: any) => {
            const { reunionId, urlOri } = result;
            const { reunion } = reunionId;
            this.urlRegresar = urlOri;
            this.infoReunion = reunion;
            this.attachments = reunion.archivos.map(function (add) {
              const data = {
                id: add.idArchivo,
                name: add.nombre,
                size: add.tamano,
              };
              return data;
            });
          })
      )
      .add(
        this.router.events.subscribe((event) => {
          if (event instanceof ResolveEnd) {
            if (!event.url.includes('mesas-interoperabilidad/home')) {
              this.MesaFunctions.delete_localstorage('dataTblInter');
              this.MesaFunctions.delete_localstorage('active');
            }
          }
        })
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subcriptions.unsubscribe();
  }

  regresar() {
    if (this.urlRegresar === 'consultar') {
      localStorage.setItem('active', '4');
      this.router.navigate(['mesas-interoperabilidad/consult/' + this.idmesa]);
    }
  }
}

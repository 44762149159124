import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { RegisterService } from 'src/app/services/register.service';

@Component({
   selector: 'app-entity-search',
   templateUrl: './entity-search.component.html',
   styleUrls: ['./entity-search.component.scss'],
})
export class EntitySearchComponent implements OnInit {
   entidadfinderData: any;
   registerForm: FormGroup;
   namePrivate: string;
   rtaSearchPriv = { entidad: null };
   bandPrivate: boolean = false;
   bandForm: boolean = false;
   subcriptions: Subscription = new Subscription();

   entidad: any = null;

   @Input() set _entidad(value) {
      this.entidad = value;
   }

   @Input() entity: any;
   @Output() dataEntity = new EventEmitter<any>();
   @Output() sendFormValid = new EventEmitter<any>();
   @Input() ies: boolean=false;

   constructor(private registerService: RegisterService, private notificaciones: NotificacionesService) {}

   ngOnInit(): void {
      this.registerForm = new FormGroup({
         nit: new FormControl('', [Validators.pattern('[0-9]+'), Validators.required]),
         dv: new FormControl('', [Validators.pattern('[0-9]+'), Validators.required]),
      });

      if (this.entity) {
         this.bandPrivate = true;

         this.namePrivate = this.entity.entidad.nombre;
         this.registerForm.get('nit').setValue(this.entity.entidad.nit);
         this.registerForm.get('dv').setValue(this.entity.entidad.dv);
      }

      if (this.entidad) {
         this.bandPrivate = true;
         this.namePrivate = this.entidad.admEntidadLiderNombre;
         this.registerForm.get('nit').setValue(this.entidad.nit);
         this.registerForm.get('dv').setValue(this.entidad.dv);
      }
   }

   validForm() {
      if (this.registerForm.invalid) {
         this.bandForm = false;

         this.sendFormValid.emit(this.bandForm);
      }
   }

   onKeyUpEventNit(type?: any) {
      this.validForm();
   }

   onKeyUpEventDv(type?: any) {
      this.validForm();
   }

   searchPrivate() {
      this.namePrivate = null;
      this.validForm();

      if (this.registerForm.invalid) {
         this.notificaciones.notificacionError('Error, por favor verifica la información suministrada');
         this.namePrivate = null;
         this.bandPrivate = false;
      } else if (this.registerForm.get('nit').value == 0) {
         this.bandPrivate = false;
         this.namePrivate = null;
         this.notificaciones.notificacionError('Error, por favor verifica la información suministrada');
      } else {
         var requestData = {
            nit: this.registerForm.get('nit').value,
            dv: this.registerForm.get('dv').value,
         };
         if(!this.ies){
            this.subcriptions.add(
               this.registerService.postPublicData('entidad/finderNIT', requestData).subscribe((data) => {
                  if (data.existRUES == false) {
                     this.notificaciones.notificacionError('Error, por favor verifica la información suministrada');

                     this.bandPrivate = false;
                     this.namePrivate = null;
                  }

                  if (data.errorWS == true) {
                     if ((data.msgErrorWS = '02_ERROR_DV: El dv no corresponde')) {
                        this.notificaciones.notificacionError('Error, por favor verifica la información suministrada');
                     } else {
                        this.notificaciones.notificacionError(
                           'El servicio web del Registro Único Empresarial y Social - RUES presenta fallas técnicas por favor contacte al administrador del sistema.'
                        );
                     }

                     this.namePrivate = null;
                  }

                  if (data.existRUES == true) {
                     this.rtaSearchPriv.entidad = data;

                     this.bandForm = true;
                     this.sendFormValid.emit(this.bandForm);
                     this.dataEntity.emit(this.rtaSearchPriv);
                     this.namePrivate = data.nombre;
                     this.bandPrivate = true;
                  }
               })
            );
         }else{
            this.subcriptions.add(
               this.registerService.postPublicData('entidad/finderIESNIT', requestData).subscribe((data) => {
                  if (data.existRUES == false) {
                     this.notificaciones.notificacionError('Conforme los datos ingresados no se encontró alguna entidad. Por favor verifique la información suministrada');

                     this.bandPrivate = false;
                     this.namePrivate = null;
                  }

                  if (data.errorWS == true) {
                     if ((data.msgErrorWS = '00_NO_ENCONTRADO_SNIES')) {
                        this.notificaciones.notificacionError('Conforme los datos ingresados no se encontró alguna entidad. Por favor verifique la información suministrada');
                     } else
                     if ((data.msgErrorWS = '02_ERROR_DV: El dv no corresponde')) {
                        this.notificaciones.notificacionError('Conforme los datos ingresados no se encontró alguna entidad. Por favor verifique la información suministrada');
                        //this.notificaciones.notificacionError('Conforme los datos ingresados no se encontró alguna entidad. Por favor verifique la información suministrada');
                     } else {
                        this.notificaciones.notificacionError(
                           'El servicio web del SNIES presenta fallas técnicas por favor contacte al administrador del sistema.'
                        );
                     }

                     this.namePrivate = null;
                  }

                  if (data.existRUES == true) {
                     this.rtaSearchPriv.entidad = data;

                     this.bandForm = true;
                     this.sendFormValid.emit(this.bandForm);
                     this.dataEntity.emit(this.rtaSearchPriv);
                     this.namePrivate = data.nombre;
                     this.bandPrivate = true;
                  }
               })
            );
         }
      }
   }

   ngOnDestroy() {
      this.subcriptions.unsubscribe();
   }
}

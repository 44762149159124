import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { LeveloneService } from './levelone.service';
import { LevelservicesService } from './levelservices.service';

@Injectable({
   providedIn: 'root',
})
export class AlertaModalService {
   constructor(private authService: AuthService, private _levelServices: LevelservicesService, private _leveloneService: LeveloneService) {}

   modalError(titulo: string, mensaje: string): void {
      Swal.fire({
         html: `
            <div class="gov-co-modal-type">
               <i class="bi bi-x-circle-fill iconoModalAlertaError"></i>
               <h2 class="tituloModalAlertaError">${titulo} </h2>
            </div>
            <div class="descripcionModalAlerta">${mensaje}</div>`,
         showCancelButton: false,
         confirmButtonText: 'Aceptar',
         confirmButtonColor: '#0943B5',
      });
   }

   modalErrorBtn(titulo: string, mensaje: string, btn: string, showCloseButton: boolean = false, classTitle: string = '') {
      return Swal.fire({
         html: `
            <div class="gov-co-modal-type">
               <i class="bi bi-x-circle-fill iconoModalAlertaError"></i>
               <h2 class="tituloModalAlertaError ${classTitle}">${titulo} </h2>
            </div>
            <div class="descripcionModalAlerta">${mensaje}</div>`,
         showCancelButton: false,
         confirmButtonText: btn,
         confirmButtonColor: '#0943B5',
         showCloseButton,
      });
   }

   modalConfirmacion(titulo: string, mensaje: string, btnConfirmar?: string) {
      return Swal.fire({
         html: `
            <div class="gov-co-modal-type">
            <i class="bi bi-check-circle-fill iconoModalAlertaConfirmacion"></i>
               <h2 class="tituloModalAlertaConfirmacion">${titulo} </h2>
            </div>
            <div class="descripcionModalAlertaError">${mensaje}</div>`,
         showCancelButton: false,
         confirmButtonText: btnConfirmar ? btnConfirmar : 'Aceptar',
         confirmButtonColor: '#0943B5',
      });
   }

   modalConfirmacionCustomBtns(titulo: string, mensaje: string, btnConfirmar: string, btnCancelar: string, btnsWhite?: boolean, widthModal?: string) {
      let swalWithBootstrapButtons = Swal.mixin({
        customClass: !btnsWhite
            ? {
                denyButton: 'btn btn-round btn-middle espaced',
                confirmButton: 'btn btn-round btn-high m-2',
              }
            : {
                denyButton: 'btn btn-round btn-middle espaced',
                confirmButton: 'btn btn-round btn-middle m-2',
              },
        buttonsStyling: false,
      });
      return swalWithBootstrapButtons.fire({
        html: `
        <div class="gov-co-modal-type mb-3">
            <span class="bi bi-check-circle-fill iconoModalAlertaConfirmacion"></span>
            <h2 class="tituloModalAlertaAdvertencia mt-2">${titulo} </h2>
        </div>
        <div class="descripcionModalConfirmacionExito">${mensaje}</div>`,
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: btnConfirmar,
        denyButtonText: btnCancelar,
        width: widthModal ? widthModal : '32rem',
      });
  }

   modalWarning(titulo: string, mensaje: string, btnConfirmar: string, showDenyButton = true, showCloseButton: boolean = false) {
      let swalWithBootstrapButtons = Swal.mixin({
         customClass: {
            denyButton: 'btn btn-round btn-middle espaced',
            confirmButton: 'btn btn-round btn-high m-2',
         },
         buttonsStyling: false,
      });
      return swalWithBootstrapButtons.fire({
         html: `
         <div class="gov-co-modal-type mb-3">
            <span class="sigmi-icon sigmi-warning icono-modal-warnig"></span>
            <h2 class="tituloModalAlertaAdvertencia mt-2">${titulo} </h2>
         </div>
         <div class="descripcionModalConfirmacionExito">${mensaje}</div>`,
         showCancelButton: false,
         showDenyButton,
         confirmButtonText: 'continuar',
         denyButtonText: `Cancelar solicitud`,
         showCloseButton,
      });
   }

   modalWarningCustomBtns(titulo: string, mensaje: string, btnConfirmar: string, btnCancelar: string, btnsWhite?: boolean, widthModal?: string) {
      let swalWithBootstrapButtons = Swal.mixin({
         customClass: !btnsWhite
            ? {
                 denyButton: 'btn btn-round btn-middle espaced',
                 confirmButton: 'btn btn-round btn-high m-2',
              }
            : {
                 denyButton: 'btn btn-round btn-middle espaced',
                 confirmButton: 'btn btn-round btn-middle m-2',
              },
         buttonsStyling: false,
      });
      return swalWithBootstrapButtons.fire({
         html: `
         <div class="gov-co-modal-type mb-3">
            <span class="sigmi-icon sigmi-warning icono-modal-warnig"></span>
            <h2 class="tituloModalAlertaAdvertencia mt-2">${titulo} </h2>
         </div>
         <div class="descripcionModalConfirmacionExito">${mensaje}</div>`,
         showCancelButton: false,
         showDenyButton: true,
         confirmButtonText: btnConfirmar,
         denyButtonText: btnCancelar,
         width: widthModal ? widthModal : '32rem',
      });
   }

   modalConfirmacionExito(titulo: string, mensaje: string, btnConfirmar: string) {
      return Swal.fire({
         html: `
            <div class="gov-co-modal-type">
            <i class="bi bi-check-circle-fill iconoModalAlertaConfirmacionExito"></i>
               <h2 class="tituloModalAlertaConfirmacionExito">${titulo} </h2>
            </div>
            <div class="descripcionModalConfirmacionExito">${mensaje}</div>`,
         showCancelButton: false,
         confirmButtonText: btnConfirmar,
         confirmButtonColor: '#0943B5',
      });
   }

   modalPregunta(titulo: string, mensaje: string): void {
      Swal.fire({
         html: `
            <div class="gov-co-modal-type">
            <i class="bi bi-x-circle-fill iconoModalAlertaError"></i>
               <h2 class="tituloModalAlertaError">${titulo} </h2>
            </div>
            <div class="descripcionModalAlerta">${mensaje}</div>`,
         showCancelButton: true,
         confirmButtonText: 'Si',
         confirmButtonColor: '#0943B5',
         cancelButtonText: 'No',
         cancelButtonColor: '#A80521',
      }).then((result) => {
         if (result.isConfirmed) {
            this._levelServices.deletedata();
            this._levelServices.deletedataEdit();
            this._leveloneService.deletedataTemp();
            this._leveloneService.deletedataTempAux();
            this.authService.logout();
         }
      });
   }

   modalPregunta2BtnsConfirmacion(titulo: string, mensaje: string, btnAceptar: string, btnCancelar: string) {
      return Swal.fire({
         html: `
            <div class="gov-co-modal-type">
              <i class="bi bi-check-circle-fill iconoModalAlertaConfirmacion"></i>
              <h2 class="tituloModalAlertaConfirmacion">${titulo} </h2>
            </div>
            <div class="descripcionModalAlerta">${mensaje}</div>`,
         showCancelButton: true,
         confirmButtonText: btnAceptar,
         confirmButtonColor: '#0943B5',
         cancelButtonText: btnCancelar,
         cancelButtonColor: '#A80521',
      });
   }
   modalPregunta2BtnsConfirmacionReverse(titulo: string, mensaje: string, btnAceptar: string, btnCancelar: string, reverseButtons: boolean = false) {
      let swalWithBootstrapButtons: any = Swal.mixin({
         customClass: {
            cancelButton: 'btn btn-round btn-middle espaced',
            confirmButton: 'btn btn-round btn-high m-2',
         },
         buttonsStyling: false,
      });
      if (reverseButtons) {
         swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-round btn-middle espaced',
               cancelButton: 'btn btn-round btn-high m-2',
            },
            buttonsStyling: false,
         });
      }
      return swalWithBootstrapButtons.fire({
         html: `
            <div class="gov-co-modal-type">
              <i class="bi bi-check-circle-fill iconoModalAlertaConfirmacion"></i>
              <h2 class="tituloModalAlertaConfirmacion">${titulo} </h2>
            </div>
            <div class="descripcionModalAlerta">${mensaje}</div>`,
         showCancelButton: true,
         confirmButtonText: btnAceptar,
         confirmButtonColor: '#0943B5',
         cancelButtonText: btnCancelar,
         cancelButtonColor: '#A80521',
         reverseButtons,
      });
   }
   modalText2Btns(
      titulo: string,
      mensaje: string,
      btnAceptar: string,
      btnCancelar: string,
      msgPlaceholder: string,
      icon: boolean = false,
      reverseButtons: boolean = false
   ) {
      let swalWithBootstrapButtons: any = Swal.mixin({
         customClass: {
            cancelButton: 'btn btn-round btn-middle espaced',
            confirmButton: 'btn btn-round btn-high m-2',
         },
         buttonsStyling: false,
      });
      if (reverseButtons) {
         swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-round btn-middle espaced',
               cancelButton: 'btn btn-round btn-high m-2',
            },
            buttonsStyling: false,
         });
      }
      return swalWithBootstrapButtons.fire({
         html: `
            <div class="gov-co-modal-type">
              ${icon ? '<i class="bi bi-check-circle-fill iconoModalAlertaConfirmacion"></i>' : ''}
               <h2 class="tituloModalTextInput">${titulo} </h2>
            </div>
            <div class="descripcionModalTextInput">${mensaje}</div>
            <div class="form-group gov-co-form-group">
            <textarea  rows="5" class="form-control" id="textAreaModalId" placeholder="${msgPlaceholder}"></textarea>
            </div>
            `,
         showCancelButton: true,
         confirmButtonText: btnAceptar,
         cancelButtonText: btnCancelar,
         reverseButtons,
         preConfirm: () => {
            let textTrim = (<HTMLInputElement>document.getElementById('textAreaModalId')).value.trim();
            (<HTMLInputElement>document.getElementById('textAreaModalId')).value = textTrim;
            if (
               (<HTMLInputElement>document.getElementById('textAreaModalId')).value &&
               (<HTMLInputElement>document.getElementById('textAreaModalId')).value !== '' &&
               (<HTMLInputElement>document.getElementById('textAreaModalId')).value.length <= 200
            ) {
               return new Promise((resolve, reject) => {
                  resolve({
                     textAreaModal: (<HTMLInputElement>document.getElementById('textAreaModalId')).value,
                  });
               });
            } else {
               const errorMessage =
                  (<HTMLInputElement>document.getElementById('textAreaModalId')).value.length <= 200
                     ? 'Este campo es requerido'
                     : 'La cantidad máxima de caracteres permitidos son 200';
               Swal.showValidationMessage(errorMessage);
            }
         },
      });
   }
}

export class ModelTable {
   optionsSelect: optionData[];
   optionsNumber: number[];
   filter: boolean;
   reporte: boolean;
   reporteLabel: string;
   columns: columnsTable[];
   columnSubRows: ArrayKey;
   rows: any[];
   isDataList: boolean;
   displayKey: string;
   styleGuide: styleGuide;
   messageEmpty: MessageEmpty;
   columnsState: columnState[];
   title: titleTable;
   page: number;
   pageSizeTable: number;
   border: boolean;
   borderContentTbl: boolean;
   borderLeftTable: boolean;
   tblGeneral: boolean;
   modalPadding: boolean;
   verticalHth: boolean;
   titlePadding: boolean;
   valueRadio: number;
   separator: boolean;
   numPages: boolean;
   pagination: boolean;
   columnsActions: string[];
   functionDisabled: any;
   functionRadioDisabled: any;
   showHeadCheckbox: boolean;
   constructor(...args: Partial<ModelTable>[]) {
      Object.assign(this, InitialTable, ...args);
   }
}
export interface ArrayKey {
   parentKey: string;
   array: string;
   key: string;
   type: 'array' | 'template';
}
export interface MessageEmpty {
   message: string;
   classMessage?: string;
   classFont?: string;
}
export interface dataEmitter {
   key: string;
   column?: string;
   data?: any;
   type?: any;
   page?: number;
   selected?: any[];
}
export interface keyValue {
   key: any;
   value: string;
}
export interface columnEvent {
   key: string;
   eventKey: any;
}
export interface columnState {
   key: string;
   classKey: keyValue[];
   filter?: boolean;
   selections?: string[];
   keySubkey?: string;
   classSubkey?: keyValue[];
}
export interface columnsTable {
   prop: string;
   name: string;
   cellClass?: string;
   cellThClass?: string;
   headerClass?: string;
   minWidth?: number;
   maxWidth?: number;
   sortable?: boolean;
   resizeable?: boolean;
   draggable?: boolean;
   propData?: any;
   cellClassAction?: string;
   oidTooltip?: boolean;
}
export interface styleGuide {
   caretClass: string;
   selectBoxClass: string;
   selectMenuClass: string;
   optionsClass: string;
}
export interface optionData {
   display: string;
   value: string;
}
export interface titleTable {
   text: string;
   class?: string;
   textMessage?: string;
   classMessage?: string;
   textEvent?: string;
   classEvent?: string;
}
const InitialTable: Partial<ModelTable> = {
   optionsSelect: [],
   filter: false,
   optionsNumber: [2, 5, 10],
   columns: [],
   rows: [],
   isDataList: true,
   displayKey: 'value',
   styleGuide: {
      caretClass: 'caret',
      selectBoxClass: 'dropdown-wrapper',
      selectMenuClass: 'dropdown',
      optionsClass: 'option',
   },
   messageEmpty: { message: 'No hay información', classMessage: 'message-empty-table', classFont: '' },
   columnsState: [],
   page: 1,
   pageSizeTable: 10,
   border: true,
   separator: true,
   numPages: true,
   pagination: true,
   tblGeneral: false,
   modalPadding: false,
   verticalHth: false,
   titlePadding: false,
   borderContentTbl: false,
   borderLeftTable: false,
   showHeadCheckbox: true,
};

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
   numRequest: number = 0;
   constructor(private spinnerService: NgxSpinnerService, private authService: AuthService) {}

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const { url } = req;
      if (!url.includes(environment.CLIENT_URL)) {
         this.spinnerService.show();
         
         const nombreusuario = this.authService.currentUserValue.primerNombre + ' ' + this.authService.currentUserValue.primerApellido;
         const user = this.authService.currentUserValue.usuario + '';
         const access_token = 'Bearer ' + localStorage.getItem('access_token_sigmi');
         
         if (access_token) {
            req = req.clone({
               setHeaders: {
                  NombreUsuario: nombreusuario,
                  User: user,
                  Authorization: access_token,
               },
            });
         }
         this.numRequest++;
      }

      return next.handle(req).pipe(
         finalize(() => {
            if (!url.includes(environment.CLIENT_URL)) {
               this.numRequest--;
               if (this.numRequest <= 0) {
                  this.spinnerService.hide();
                  this.numRequest = 0;
               }
            }
         })
      );
   }
}

<!-- Breadcrumb Container -->
<div class="container p-0">
    <div class="row" style="align-items: baseline">
       <div class="col-md-10">                  
          <nav 
                class="breadcrumb" 
                aria-label="breadcrumb" 
                *ngIf="modelBreadCrumb != null"
            >            
             <ol class="breadcrumb">
                <li [class]="'breadcrumb-item '" *ngFor="let breadcrumbItem of modelBreadCrumb.last; index as i">
                   <a class="textoBrdCrumb" [routerLink]="[breadcrumbItem.url]"> {{breadcrumbItem.nombre}} </a> 
                   <!-- "['/app/home']",  "['/app/administration']", Inicio,  Administración  -->
                </li>
                <li [class]="'breadcrumb-item active'" aria-current="page">
                      <span class="breadcrumb-text item-link">{{modelBreadCrumb.current}}</span>
                </li>
             </ol>
          </nav>
       </div>
       <div class="col-md-2 mt-4" style="text-align: right">
          <span class="col-md-2 etiquetaRol">
             {{ this.userData.rol.nombre }}
          </span>
       </div>
    </div>
 </div>

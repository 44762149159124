import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import { HttpClient } from '@angular/common/http';
import { urlsPublic, urlsPrivate } from 'src/environments/url-services';
import {Ipasswordreset} from "../site/password-reset/password-reset-init/ipasswordreset";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetInitService {

  constructor(private http: HttpClient) { }

  solicitud(correo: string, entidad: string): Observable<{}> {
    let data: Ipasswordreset = {
      correo: correo,
      entidadId: entidad,
    };
    return this.http.post(`${environment.server_api_url}${urlsPrivate.reset_init}`, data);
  }

  listarEntidades$(): Observable<any> {
    return this.http.get(`${environment.server_api_url}${urlsPublic.listarEntidades}`);
  }


}

import { version } from '../../package.json';

export const environment = {
   production: true,
   server_api_url: 'https://sigmi.mintic.gov.co', //prod
   CLIENT_ID: 'sigmiPRO', // prod
   CLIENT_URL: 'https://autenticaciondigital.and.gov.co', // prod
   RECAPTCHA_KEY: '6LcMK3MeAAAAAPHeZGyUnECTvGCUZh-pQ47KivdP',
   RECAPTCHA_KEY_ACTIVATED: false,
   app_version: version,
   PSA_TABLE_INTEROPERABILITY: '/bodegasigmi/mesas',
   FOLDER_LEVEL_THREE: '/bodegasigmi/niveles/nivel3/solicitudes/normatividad',
   FOLDER_LEVEL_ONE: '/bodegasigmi/niveles/nivelI/solicitudes/normatividad',
   FOLDER_CREATE_DATA: '/bodegasigmi/niveles/nivelI/solicitudes/normatividad',
   FOLDER_LAYERS: '/bodegasigmi/capas',
   FOLDER_LEVEL_THREE_POLITICO: '/bodegasigmi/niveles/nivelIII/solicitudes/politico',
   FOLDER_LEVEL_THREE_ORGANIZACIONAL: '/bodegasigmi/niveles/nivelIII/solicitudes/organizacional',
   FOLDER_LEVEL_THREE_TECNICO: '/bodegasigmi/niveles/nivelIII/solicitudes/tecnico'
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { urlsPrivate } from 'src/environments/url-services';

@Injectable({
   providedIn: 'root',
})
export class SharedService {
   url: string = environment.server_api_url;

   subject = new Subject<any>();
   resetDataSubject = this.subject.asObservable();

   constructor(public http: HttpClient) {}

   getAll = (nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPrivate[nameService]}`);
   getAllType = (nameService: string, type: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${type}`);
   getOne = (id: number, nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${id}/`);
   getQueryParams = (nameService: string, params: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPrivate[nameService]}?${params}`);
   post = (item: any, nameService: string): Observable<any> => this.http.post<any>(`${this.url}${urlsPrivate[nameService]}`, item);
   // postWithOutItem = (nameService: string): Observable<any> => this.http.post<any>(`${this.url}${urlsPrivate[nameService]}`);
   addOne = (item: any, nameService: string): Observable<any> => this.http.post<any>(`${this.url}${urlsPrivate[nameService]}`, item);
   addMany = (items: any[]): Observable<any> => this.http.post<any>(this.url, items);
   updateOne = (item: any, nameService: string, id?: number): Observable<any> =>
      this.http.put<any>(`${this.url}${urlsPrivate[nameService]}${id? id: ''}${id ? '/' : ''}`, item);

   patchOne = (id: number, item: any, nameService: string): Observable<any> =>
      this.http.patch<any>(`${this.url}${urlsPrivate[nameService]}${id}/`, item);

   updateMany = (item: any[]): Observable<any> => this.http.put<any[]>(this.url, item);
   removeOne = (id: any, nameService: string): Observable<any> => this.http.delete<any>(`${this.url}${urlsPrivate[nameService]}${id}`);
   removeMany = (item: any, nameService: string): Observable<any> => this.http.delete<any>(`${this.url}${urlsPrivate[nameService]}`, item);

   removeBodyOne = (body: any, nameService: string): Observable<any> => {
      const options = {
         headers: new HttpHeaders({
            'Content-Type': 'application/json',
         }),
         body,
      };
      return this.http.delete<any>(`${this.url}${urlsPrivate[nameService]}`, options);
   };

   returnUrlEnum = (nameService: string) => urlsPrivate[nameService];

   getTypeId(id: number, type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${type}${id}`);
   }

   getIdType(id: number, type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}`);
   }

   // get_servicios_notificados_levelthree = '/api/entidad/2/servicios/notificados?nivel=2,3&tipoSolicitudId=2'
   getIdTwoParamsType(id: number, type: string, firstParam: string, typeTwo: string, secondParam: number, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}${firstParam}${typeTwo}${secondParam}`);
   }

   getIdsType(id: number, service: number, type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${id}/${service}/${type}`);
   }

   postIdType(item: any, id: number, type: string, nameService: string): Observable<any> {
      return this.http.post<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}`, item);
   }
   deleteIdType(item: any, id: number, type: string, nameService: string): Observable<any> {
      return this.http.delete<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}/${item}`);
   }
   updateIdType(item: any, id: number, type: string, nameService: string): Observable<any> {
      return this.http.put<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}`, item);
   }
   updateType(item: any[], type: string, nameService: string): Observable<any> {
      return this.http.put<any[]>(`${this.url}${urlsPrivate[nameService]}${type}`, item);
   }
   postIdTwoTypes(item: any, id: number, type: string, idTwo: number, typeTwo: string, nameService: string): Observable<any> {
      return this.http.post<any>(`${this.url}${urlsPrivate[nameService]}${id}/${type}/${idTwo}/${typeTwo}`, item);
   }
   postTwoIdNoType(item: any, id: number, idTwo: number, nameService: string): Observable<any> {
      return this.http.post<any>(`${this.url}${urlsPrivate[nameService]}${id}/${idTwo}`, item);
   }

   add_localstorage = (name: string, items: any) => localStorage.setItem(name, JSON.stringify(items));
   get_localstorage = (name: string) => JSON.parse(localStorage.getItem(name));
   delete_localstorage = (name: string) => localStorage.removeItem(name);

   dispachData = (data: any) => this.subject.next(data);
}

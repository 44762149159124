<!-- contenedor principal -->
<div class="container">
   <div class="row" style="margin-bottom: 60px">
      <div class="col-md-8">
         <div class="headline-xl">Mesa de interoperabilidad - {{ verMesaId?.nombre }}</div>
         <div class="headline-m mt-4">A continuación puede consultar la mesa de interoperabilidad</div>
      </div>
   </div>

   <div class="container p-0">
      <div class="tabs-govco">
         <nav>
            <div ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs" role="tablist">
               <!-- Información de la mesa -->
               <a class="nav-link" [ngbNavItem]="1" (click)="guardarTabActual('1')" ngbNavLink>
                  Información de la mesa

                  <ng-template ngbNavContent>
                     <div class="container m-4 p-0 pt-4">
                        <div class="row">
                           <div class="col">
                              <h3 class="headline-s">Información de la mesa de interoperabilidad</h3>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-md-9">
                              <label class="body-1 mb-1">Consulte todos campos y al final continue por los siguientes apartados.</label>
                           </div>
                        </div>

                        <div class="row pt-2 mt-4 mb-2">
                           <div class="col-md-4">
                              <label class="body-1 mb-1 titleForm">Nombre de la mesa de interoperabilidad</label>
                              <br />
                              <label class="ps-3 body-1">{{ verMesaId?.nombre }}</label>
                           </div>
                           <div class="col-md-4">
                              <label class="body-1 mb-1 titleForm">Estado de la mesa de interoperabilidad</label>
                              <br />
                              <label class="body-1 ps-4 mt-2" *ngIf="verMesaId?.estado">Abierta</label>
                              <label class="body-1 ps-4 mt-2" *ngIf="!verMesaId?.estado">Cerrada</label>
                           </div>
                        </div>

                        <div class="row mt-4 mb-2">
                           <div class="col-md-8">
                              <label class="body-1 mb-1 titleForm">Objetivo</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.objetivo }}</label>
                           </div>
                        </div>

                        <div class="row mt-4 mb-2">
                           <div class="col-md-8">
                              <label class="body-1 mb-1 titleForm">Descripción general</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.descripcion }}</label>
                           </div>
                        </div>

                        <div class="row pt-2 mb-2">
                           <div class="col-md-4">
                              <label class="body-1 mb-1 titleForm">Entidad Líder</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.admEntidadLiderNombre }}</label>
                           </div>
                           <div class="col-md-4">
                              <label class="body-1 mb-1 titleForm">Consultor principal de apoyo</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.admUsuarioNombre }}</label>
                           </div>
                        </div>

                        <div class="row pt-2 mt-4 mb-3">
                           <div class="col-md-4">
                              <label class="body-1 mb-1 titleForm">Correo del responsable de la entidad lider</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.emailResponsableEntidad }}</label>
                           </div>
                           <div class="col-md-4">
                              <label class="body-1 mb-1 titleForm">Correo del consultor de apoyo principal</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.emailConsultor }}</label>
                           </div>
                        </div>

                        <div class="row pt-2 mt-4 mb-5 pb-5">
                           <div class="col-md-8">
                              <label class="body-1 mb-1 titleForm">Fecha de creación</label>
                              <br />
                              <label class="body-1 ps-3">{{ verMesaId?.fechaCreacion }}</label>
                           </div>
                        </div>

                        <div class="row-md-6 mt-4">
                           <a class="btn btn-round btn-middle" (click)="volverConsultarMesa()">VOLVER</a>
                        </div>
                     </div>
                  </ng-template>
               </a>

               <!-- Entidades participantes -->
               <a class="nav-link" [ngbNavItem]="2" (click)="guardarTabActual('2')" ngbNavLink>
                  Entidades participantes
                  <ng-template ngbNavContent>
                     <div class="p-0">
                        <div class="row">
                           <div class="col-md-8" style="margin-top: 50px; margin-left: 20px">
                              <h6 class="headline-s">Entidades participantes seleccionadas</h6>
                           </div>
                           <div [class]="habilitarTablaEntidades ? 'col-md-12 p-0' : 'col-md-12 divSinResultados mt-5'">
                              <div *ngIf="habilitarTablaEntidades === true; then resultados; else noresultados"></div>
                              <ng-template #resultados>
                                 <app-sigmi-table [_modelTable]="modelTableEntities"></app-sigmi-table>
                              </ng-template>
                              <ng-template #noresultados>
                                 <div class="containerSinResultados">
                                    <label class="body-1 text-center">No tiene ninguna entidad registrada.</label>
                                 </div>
                              </ng-template>
                           </div>
                        </div>
                        <div class="row-md-6 mt-4 ms-3 mb-4">
                           <a class="btn btn-round btn-middle" (click)="volverConsultarMesa()">VOLVER</a>
                        </div>
                     </div>
                  </ng-template>
               </a>

               <!-- Servicios de la mesa -->
               <a class="nav-link" [ngbNavItem]="3" (click)="guardarTabActual('3')" ngbNavLink>
                  Servicios de la mesa
                  <ng-template ngbNavContent>
                     <div class="p-0">
                        <div class="row">
                           <div class="col-md-8 divSinResultados" style="margin-top: 50px">
                              <h6 class="headline-s">Servicios de la mesa</h6>
                           </div>
                           <div [class]="habilitarTablaServicios ? 'col-md-12 p-0' : 'col-md-12 divSinResultados mt-5'">
                              <div *ngIf="habilitarTablaServicios === true; then resultados; else noresultados"></div>
                              <ng-template #resultados>
                                 <app-sigmi-table [_modelTable]="modelTableServices"></app-sigmi-table>
                              </ng-template>
                              <ng-template #noresultados>
                                 <div class="containerSinResultados">
                                    <label class="body-1 text-center">No tiene ningún servicio registrado.</label>
                                 </div>
                              </ng-template>
                           </div>
                        </div>
                        <div class="row-md-6 mt-4 ms-3 mb-4">
                           <a class="btn btn-round btn-middle" (click)="volverConsultarMesa()">VOLVER</a>
                        </div>
                     </div>
                  </ng-template>
               </a>

               <!-- Reuniones de avance -->
               <a class="nav-item nav-link" [ngbNavItem]="4" (click)="guardarTabActual('4')" ngbNavLink>
                  Reuniones de avance
                  <ng-template ngbNavContent>
                     <div class="p-0">
                        <div class="row">
                           <div class="col-md-8 divSinResultados" style="margin-top: 50px">
                              <h6 class="headline-s">Reuniones registradas</h6>
                           </div>

                           <div [class]="habilitarTablaReuniones ? 'col-md-12 p-0' : 'col-md-12 divSinResultados mt-5'">
                              <div *ngIf="habilitarTablaReuniones === true; then resultados; else noresultados"></div>
                              <ng-template #resultados>
                                 <app-sigmi-table [_modelTable]="modelTableMeeting" (eventEmitter)="receiveEventMeeting($event)"></app-sigmi-table>
                              </ng-template>
                              <ng-template #noresultados>
                                 <div class="containerSinResultados">
                                    <label class="body-1 text-center">Aún no hay reuniones de avance registradas.</label>
                                 </div>
                              </ng-template>
                           </div>
                        </div>

                        <div class="row-md-6 mt-4 ms-3 mb-4">
                           <a class="btn btn-round btn-middle" (click)="volverConsultarMesa()">VOLVER</a>
                        </div>
                     </div>
                  </ng-template>
               </a>
            </div>
         </nav>
      </div>
      <div [ngbNavOutlet]="nav" class="mb-5"></div>
   </div>
</div>

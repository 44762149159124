export class Breadcrumb {
    last: Array<BreadcrumbItem>;
    current: string;
    constructor(...args: Partial<Breadcrumb>[]) {
        Object.assign(this, InitialTable, ...args);
     }
}

export class BreadcrumbItem {
    nombre: string;
    url: string;
}

const InitialTable: Partial<Breadcrumb> = {
    last: [{
        url: "/app/home",
        nombre: "Inicio"
    }],
    current: "Administración",
}
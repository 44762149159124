import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { urlsPublic } from 'src/environments/url-services';
import { User } from '../models/entities';

@Injectable({
   providedIn: 'root',
})
export class AuthService {
   userData = new BehaviorSubject<User>(new User());
   public currentUser: Observable<User>;

   constructor(private http: HttpClient, private router: Router, private oauthService: OAuthService) {
      this.currentUser = this.userData.asObservable();
   }

   login(credentialData: any): Observable<any> {
      return this.http.post(`${environment.server_api_url}${urlsPublic.login}`, credentialData);
   }

   setUserDetails() {
      if (localStorage.getItem('payload')) {
         let userDetails = new User();
         const decodeUserDetails: User = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('payload'))));
         userDetails = decodeUserDetails;
         this.userData.next(userDetails);
      }
   }

   public get currentUserValue(): User {
      return this.userData.value;
   }

   logout() {
      this.oauthService.logOut(false);
      this.oauthService.revokeTokenAndLogout();
      const entitiesData = JSON.parse(localStorage.getItem('entitiesData'));
      localStorage.clear();
      localStorage.setItem('entitiesData', JSON.stringify(entitiesData));
      this.router.navigate(['']);
      this.userData.next(new User());
   }
}

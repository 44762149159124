<app-header-site [_userData]="user"></app-header-site>
<div class="container mt-4 mb-5 pt-5 pb-4">
   <div class="row">
      <div class="col">
         <h2 class="headline-xl">Le damos la bienvenida al Sistema de Información de Gestión del Marco de Interoperabilidad</h2>
      </div>
   </div>
   <div class="row mt-4">
      <div class="col">
         <p>
            {{ nameUser }}, a continuación, deberá seleccionar la entidad con la cuál desea ingresar a SIGMI. <br />
            Recuerde que si desea registrar una nueva entidad deberá cerrar sesión y realizar el proceso en el enlace de registrar un nuevo usuario.
         </p>
      </div>
   </div>
   <div class="row mt-4 mb-5">
      <div class="col col-md-4">
         <h2 class="headline-m">Entidades</h2>
         <div class="col-md-9 d-flex flex-column justify-content-end">
            <div class="position-relative">
               <input
                  id="Search"
                  class="form-control px-3 barSearchSigmi"
                  type="search"
                  style="width: 100%"
                  placeholder="Buscar por palabra"
                  [autocomplete]="false"
                  [(ngModel)]="filterEntity"
                  (input)="clearFilter()"
               />
               <button type="button" class="btn-search-sigmi" (click)="eventFilter()">
                  <span class="bi bi-search"></span>
               </button>
            </div>
         </div>
      </div>
   </div>
   <div class="row pt-3">
      <div class="col">
         <div class="d-flex gap-2 flex-wrap">
            <button class="content-entity border" *ngFor="let entity of entities" (click)="loadUser(entity)" [disabled]="!entity.estadoUsuario">
               <div class="content-entity-img">
                  <img src="assets/images/Icon_map-museum{{ !entity.estadoUsuario ? '_white' : '' }}.svg" alt="" srcset="" width="50px" />
                  <span>
                     {{ entity.nombre }}
                  </span>
               </div>
               <span class="error-entity text-center">
                  {{ entity.usuarioPorAprobar ? 'Usuario pendiente por aprobación-MinTIC' : !entity.estadoUsuario ? 'Usuario inactivo' : '' }}
               </span>
            </button>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>

import { Component, OnInit, Input, HostListener, EventEmitter, Output, ViewChild } from '@angular/core';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';

import * as fromModel from '../../models';

@Component({
   selector: 'app-sigmi-table',
   templateUrl: './sigmi-table.component.html',
   styleUrls: ['./sigmi-table.component.scss'],
})
export class SigmiTableComponent implements OnInit {
   modelTable: fromModel.ModelTable;

   isDataList: boolean = true;
   displayKey: string;
   styleGuide: fromModel.styleGuide;
   pageSize: any;
   pageSizeTable: any;
   pipeKey: string;
   pipeValue: string;
   pipeEquals: boolean;
   filterColumn: string;
   filterValue: string;

   optionsSelect: string[] = [];
   optionsNumber: number[] = [];
   filter: boolean;
   reporte: boolean;
   reporteLabel: string;
   numPage: number[];
   columns: fromModel.columnsTable[];
   columnSubRows: fromModel.ArrayKey;
   rows: any[];
   subRows: any[];
   messageEmpty: fromModel.MessageEmpty;
   columnsState: fromModel.columnState[];
   title: fromModel.titleTable;
   border: boolean;
   borderContentTbl: boolean;
   borderLeftTable: boolean;
   tblGeneral: boolean;
   modalPadding: boolean;
   verticalHth: boolean;
   titlePadding: boolean;
   separator: boolean;
   numPages: boolean;
   pagination: boolean;

   valueRadio: number;

   total: number;
   columnFilterDisplay: string;
   withCheckbox: boolean;
   headCheckbox: boolean = false;
   selectionCheck: any[];
   functionDisabled: any;
   functionRadioDisabled: any;

   columnsActions: string[];

   showHeadCheckbox: boolean;
   public oidModal = false;
   public oidDetailsList: Array<fromModel.optionData> = new Array<fromModel.optionData>();
   //@ViewChild('MdTooltip') tooltip!: MdTooltip;
   @Input()
   public set _modelTable(model: fromModel.ModelTable) {
      this.modelTable = model;

      this.optionsSelect = model?.optionsSelect?.map((option) => option.display);
      this.filter = model?.filter;
      this.reporte = model?.reporte;
      this.reporteLabel = model?.reporteLabel ? model?.reporteLabel : 'DESCARGAR TABLA';
      this.optionsNumber = model?.optionsNumber;
      this.columns = model?.columns;
      this.columnSubRows = model?.columnSubRows;
      this.rows = model?.rows;
      this.isDataList = model?.isDataList;
      this.displayKey = model?.displayKey;
      this.styleGuide = model?.styleGuide;
      this.messageEmpty = model?.messageEmpty;
      this.columnsState = model?.columnsState;
      this.title = model?.title;
      this.border = model?.border;
      this.borderContentTbl = model?.borderContentTbl;
      this.borderLeftTable = model?.borderLeftTable;
      this.tblGeneral = model?.tblGeneral;
      this.modalPadding = model?.modalPadding;
      this.verticalHth = model?.verticalHth;
      this.titlePadding = model?.titlePadding;
      this.separator = model?.separator;
      this.numPages = model?.numPages;
      this.pagination = model?.pagination;

      this.functionDisabled = model?.functionDisabled;
      this.functionRadioDisabled = model?.functionRadioDisabled;

      this.total = model?.rows?.length;
      this.pageSizeTable = model?.pageSizeTable;

      this.page = model?.page;

      this.columnsActions = model?.columnsActions || ['accion', 'actividad'];

      this.valueRadio = model?.valueRadio;
      this.withCheckbox = this.columns?.some((x) => x.prop === 'checkbox');
      this.showHeadCheckbox = model?.showHeadCheckbox;
      this.selectionCheck = [];
      if (this.withCheckbox) {
         this.selectionItemsWithFilter();
      }
   }

   ColumnMode = ColumnMode;
   SortType = SortType;

   page: number;
   pagina = '1';

   @Output() eventEmitter = new EventEmitter<fromModel.dataEmitter>();
   displayRowid: string;

   constructor() {}
   ngOnInit() {}
   @HostListener('click')
   onClick() {
      if (this.columnFilterDisplay) {
         this.columnFilterDisplay = undefined;
      }
   }

   setFilterStatus = (key: any, value: any) => {
      this.columnFilterDisplay = undefined;
      this.pipeKey = key;
      this.pipeValue = value;
      this.pipeEquals = true;
      this.filterColumn = undefined;
      this.filterValue = undefined;
   };
   clearFilterStatus() {
      this.columnFilterDisplay = undefined;
      this.pipeKey = undefined;
      this.pipeValue = undefined;
      this.pipeEquals = undefined;
   }
   clearFilter() {
      if (this.filterColumn == null || this.filterValue === '') {
         this.pipeKey = undefined;
         this.pipeValue = undefined;
         this.pipeEquals = undefined;
         if (this.withCheckbox) {
            this.selectionItemsWithoutFilter();
         }
      }
   }
   eventFilter() {
      this.pipeKey = this.modelTable.optionsSelect.find((option) => option.display === this.filterColumn)?.value;
      this.pipeValue = this.filterValue;
      if (this.withCheckbox) {
         this.selectionItemsWithFilter();
      }
   }
   onPaginatorChange(number: any) {
      this.page = number;
      this.pagina = number.toString();
   }
   isSelectedAll = () => {
      const indexStart = this.pageSizeTable * (this.page - 1);
      const indexEnd = indexStart + this.pageSizeTable - 1;

      let filterSelected = this.rows;
      if (this.pipeValue) {
         filterSelected = this.rows?.filter((x) => x[this.pipeKey]?.toLowerCase().includes(this.pipeValue?.toLowerCase()));
      }

      return filterSelected.filter((x, i) => i >= indexStart && i <= indexEnd).filter((x) => !x.checkbox).length > 0;
   };
   returnInterminate() {
      const indexStart = this.pageSizeTable * (this.page - 1);
      const indexEnd = indexStart + this.pageSizeTable - 1;

      let filterSelected = this.rows;
      if (this.pipeValue) {
         filterSelected = this.rows?.filter((x) => x[this.pipeKey]?.toLowerCase().includes(this.pipeValue?.toLowerCase()));
      }
      const numChecked = filterSelected.filter((x, i) => i >= indexStart && i <= indexEnd).filter((x) => !x.checkbox);
      const numUnchecked = filterSelected.filter((x, i) => i >= indexStart && i <= indexEnd).filter((x) => x.checkbox);
      return numChecked.length > 0 && numUnchecked.length > 0 && numChecked !== numUnchecked;
   }
   selectionAll = () => {
      this.headCheckbox = this.isSelectedAll();
      const indexStart = this.pageSizeTable * (this.page - 1);
      const indexEnd = indexStart + this.pageSizeTable - 1;

      let filterSelected = this.rows;
      if (this.pipeValue) {
         filterSelected = this.rows?.filter((x) => x[this.pipeKey]?.toLowerCase().includes(this.pipeValue?.toLowerCase()));
      }

      filterSelected
         .filter((x, i) => i >= indexStart && i <= indexEnd)
         .forEach((x, i) => {
            if (x.checkbox !== this.headCheckbox) {
               x.checkbox = this.headCheckbox;
               this.selectionItems(this.page, i, x);
            }
         });

      this.eventEmitter.emit({ key: 'checkbox', data: this.rows, type: 'selectHead', page: this.page, selected: this.selectionCheck });
   };
   selectRow = (i: number, row: any) => {
      row.checkbox = !row.checkbox;
      this.selectionItems(this.page, i, row);
      this.eventEmitter.emit({ key: 'checkbox', data: row, type: 'selectRow', page: this.page, selected: this.selectionCheck });
   };
   selectionItemsWithoutFilter = () => {
      const pages = Math.trunc(this.rows?.length / this.pageSizeTable) + (this.rows?.length % this.pageSizeTable === 0 ? 0 : 1);
      this.selectionCheck = [];
      [...Array(pages).keys()].forEach((page) => {
         const indexStart = this.pageSizeTable * page;
         const indexEnd = indexStart + this.pageSizeTable - 1;

         let filterSelected = this.rows;
         if (this.pipeValue) {
            filterSelected = this.rows?.filter((x) => x[this.pipeKey]?.toLowerCase().includes(this.pipeValue?.toLowerCase()));
         }
         filterSelected
            .filter((x, i) => i >= indexStart && i <= indexEnd)
            .forEach((x, i) => {
               if (x.checkbox) {
                  this.selectionItems(page + 1, i, x);
               }
            });
      });
      this.eventEmitter.emit({ key: 'checkbox', data: this.rows, type: 'selectRow', page: this.page, selected: this.selectionCheck });
   };
   selectionItemsWithFilter = () => {
      this.selectionCheck = [];
      let filterSelected = this.rows;
      if (this.pipeValue) {
         this.rows
            ?.filter((x) => !x[this.pipeKey]?.toLowerCase().includes(this.pipeValue?.toLowerCase()))
            .filter((x) => x.checkbox)
            .forEach((x, i) => this.selectionItems(0, i, x));

         filterSelected = this.rows?.filter((x) => x[this.pipeKey]?.toLowerCase().includes(this.pipeValue?.toLowerCase()));
      }
      const pages = Math.trunc(filterSelected?.length / this.pageSizeTable) + (filterSelected?.length % this.pageSizeTable === 0 ? 0 : 1);
      [...Array(pages).keys()].forEach((page) => {
         const indexStart = this.pageSizeTable * page;
         const indexEnd = indexStart + this.pageSizeTable - 1;

         filterSelected
            .filter((x, i) => i >= indexStart && i <= indexEnd)
            .forEach((x, i) => {
               if (x.checkbox) {
                  this.selectionItems(page + 1, i, x);
               }
            });
      });
      this.eventEmitter.emit({ key: 'checkbox', data: this.rows, type: 'selectRow', page: this.page, selected: this.selectionCheck });
   };
   selectionItems = (page: number, i: number, row: any) => {
      if (row.checkbox) {
         this.selectionCheck.push({ page, index: i, row: { ...row } });
      } else {
         this.selectionCheck.splice(
            this.selectionCheck.findIndex((x) => x.page === page && x.index === i),
            1
         );
      }
   };

   returnSubdata = (key: string, row: any) => {
      const column = this.columnsState.find((x) => x.key === key)?.keySubkey;
      return row[column];
   };
   returnState = (key: any) => this.columnsState.some((x) => x.key == key);
   returnSubstate = (key: string, data: any) => this.returnRadioDisabled(data) && this.returnState(key);
   returnClass = (key: any, value: any) => this.columnsState.find((x) => x.key == key)?.classKey?.find((x) => x.key === value)?.value;
   returnSubClass = (key: any, value: any) => this.columnsState.find((x) => x.key == key)?.classSubkey?.find((x) => x.key === value)?.value;
   returnSelections = (key: any) => this.columnsState.find((x) => x.key == key)?.selections;
   returnNum = (key: any, select: any) => this.modelTable.rows.filter((x) => x[key] === select)?.length;
   setDisplay = (key?: any) => (this.columnFilterDisplay = key);
   displayFilter = (key: any) => this.columnFilterDisplay === key;
   returnFilter = (key: any) => this.columnsState.some((x) => x.key === key && x.filter === true);
   returnEvents = (value: any) => value?.split('/');
   emitterEvent = (item: any, row?: any, type?: any, column?: string) =>
      this.eventEmitter.emit({ key: item, column, data: row, type, page: this.page });
   downloadTable = (data?: any) => this.eventEmitter.emit({ key: 'DESCARGA', data: this.rows });
   returnType = (value: any) => value !== 'icon';
   returnSubRows = (row: any) => row[this.columnSubRows?.array];
   displayRows = (row: any) => (this.displayRowid = this.displayRowid === row[this.columnSubRows?.key] ? undefined : row[this.columnSubRows?.key]);
   orderWithCheckbox = () => (this.withCheckbox ? (this.pipeValue ? this.selectionItemsWithFilter() : this.selectionItemsWithoutFilter()) : '');
   returnDisabled = (item: any, data: any) => (this.functionDisabled ? this.functionDisabled(item, data) : false);
   returnRadioDisabled = (data: any) => (this.functionRadioDisabled ? this.functionRadioDisabled(data) : false);

   public oidToolTip(item : any){
      if(item){
         let row = this.modelTable.rows.find(x=> {return x.oid == item});
         if(row){ 
            this.oidDetailsList = row.oidDetails;
            this.oidModal = true;
         }
         
         
      }
   }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.services';
import { urlsPrivate } from 'src/environments/url-services';
import { environment } from 'src/environments/environment';
@Injectable({
   providedIn: 'root',
})
export class LeveloneService extends SharedService {
   url: string = environment.server_api_url;

   dataLevelone: any;
   dataLeveloneTemp: any;

   subjectSaveData = new Subject<any>();
   resetDataSubjectSaveData = this.subjectSaveData.asObservable();
   /* Create data element */
   dataLeveloneElementTemp: any;
   dataLeveloneTempAux: any;
   /* ******************* */
   constructor(http: HttpClient) {
      super(http);
   }
   getOntoAll(type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${type}`);
   }
   setdata = (data: any) => (this.dataLevelone = data);
   getdata = () => this.dataLevelone;
   deletedata = () => (this.dataLevelone = undefined);

   setdataTemp = (data: any) => (this.dataLeveloneTemp = data);
   getdataTemp = () => this.dataLeveloneTemp;
   deletedataTemp = () => (this.dataLeveloneTemp = undefined);

   setdataTempAux = (data: any) => (this.dataLeveloneTempAux = data);
   getdataTempAux = () => this.dataLeveloneTempAux;
   deletedataTempAux = () => (this.dataLeveloneTempAux = undefined);

   dispachDataSave = (data: any) => this.subjectSaveData.next(data);

   /* create data element */
   setdataElementTemp = (data: any) => (this.dataLeveloneElementTemp = data);
   getdataElementTemp = () => this.dataLeveloneElementTemp;
   deletedataElementTemp = () => (this.dataLeveloneTemp = undefined);

}

import {
   ComponentFactoryResolver,
   ComponentRef,
   Directive,
   ElementRef,
   Input,
   OnDestroy,
   OnInit,
   Renderer2,
   Self,
   ViewContainerRef,
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { EMPTY, merge, Observable, Subscription } from 'rxjs';
import { MessageCtrolComponent } from '../components/message-ctrol/message-ctrol.component';
import { MessageModel } from '../models/message-model';
import { ValidFormDirective } from './valid-forms.directive';

@Directive({
   selector: '[validCtrl]',
})
export class ValidCtrlDirective implements OnInit, OnDestroy {
   subcriptions: Subscription = new Subscription();
   @Input('validCtrl') messageCtrl: MessageModel[];
   submit$: Observable<Event>;
   ref: ComponentRef<MessageCtrolComponent>;
   initCtrol: boolean;

   constructor(
      @Self() private ctrl: NgControl,
      private formGroup: FormGroupDirective,
      private form: ValidFormDirective,
      private vcr: ViewContainerRef,
      private resolver: ComponentFactoryResolver,
      private element: ElementRef,
      private renderer: Renderer2
   ) {
      this.submit$ = this.form ? this.form.submit$ : EMPTY;
   }
   ngOnInit(): void {
      this.subcriptions.add(
         merge(this.submit$, this.formGroup.valueChanges).subscribe((submit) => {
            if (this.ctrl.touched || this.ctrl.dirty) {
               this.initCtrol = true;
               this.vaildate();
            }
            if (this.initCtrol && !this.ctrl.touched && !this.ctrl.dirty) {
               this.vaildate();
            }
         })
      );
      if (this.ctrl.touched) {
         this.vaildate();
      }
   }
   ngOnDestroy(): void {
      this.subcriptions.unsubscribe();
   }
   vaildate() {
      if (this.validCtrol()) {
         const { errors } = this.formGroup.form;
         if (errors) {
            this.validForm(errors);
         }
      }
   }
   validForm(errors: any) {
      Object.keys(errors).forEach((error) => {
         if (this.ctrl.name === errors[error].base || this.ctrl.name === errors[error].parent) {
            if (this.ctrl.touched || this.ctrl.dirty) {
               const { message } = this.messageCtrl?.find((mess) => mess.key === error);
               this.setMessageCtrl(message);
            }
         }
      });
   }
   validCtrol() {
      const { errors } = this.ctrl;
      if (errors) {
         const error = Object.keys(errors)[0];
         let message = this.messageCtrl.find((mess) => mess.key === error)?.message;
         message = message ? message : 'Error sin mensaje';
         this.setMessageCtrl(message);
         return false;
      } else {
         this.setMessageCtrl();
         return true;
      }
   }
   setMessageCtrl(text?: string) {
      if (text) {
         if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(MessageCtrolComponent);
            this.ref = this.vcr.createComponent(factory);
         }
         this.ref.instance._text = text;

         const elem = this.element.nativeElement;
         const lbl = this.element.nativeElement.parentElement.querySelector('label');

         const compMessage = this.element.nativeElement.parentElement.querySelector('.message-form');
         this.renderer.addClass(elem, 'form-error');
         this.renderer.addClass(elem, 'ctrl-form-error');
         if(lbl){
            this.renderer.addClass(lbl, 'form-error');
         }
         this.renderer.addClass(compMessage, 'form-error');

         const calendar = this.element.nativeElement.parentElement.querySelector('.p-inputtext');
         if (calendar) {
            this.renderer.addClass(calendar, 'form-error');
            this.renderer.addClass(calendar, 'ctrl-calendar-error');

            this.renderer.removeClass(elem, 'form-error');
            this.renderer.removeClass(elem, 'ctrl-form-error');

            const pButton = this.element.nativeElement.parentElement.querySelector('.p-button');
            if (pButton !== null) {
               this.renderer.addClass(pButton, 'form-error');
               this.renderer.addClass(pButton, 'ctrl-button-calendar-error');
            }
         }
         const autocomplete = this.element.nativeElement.parentElement.querySelector('.ng-autocomplete');
         if (autocomplete) {
            const inputAutocomplete = this.element.nativeElement.parentElement.querySelector('input');
            this.renderer.addClass(inputAutocomplete, 'ng-autocomplete-error');
            this.renderer.addClass(inputAutocomplete, 'form-error');
            this.renderer.removeClass(elem, 'ctrl-form-error');
         }
         const ckeditor = this.element.nativeElement.parentElement.querySelector('.ck-editor');
         if (ckeditor) {
            const inputCkeditor = this.element.nativeElement.parentElement.querySelector('.ck-content');
            this.renderer.addClass(inputCkeditor, 'ck-editor-error');
            this.renderer.addClass(inputCkeditor, 'form-error');
            this.renderer.removeClass(elem, 'ctrl-form-error');
         }
      } else {
         this.ref = undefined;
         this.vcr.clear();
         const elem = this.element.nativeElement;
         const lbl = this.element.nativeElement.parentElement.querySelector('label');
         const compMessage = this.element.nativeElement.parentElement.querySelector('.message-form');

         if (elem?.getAttribute('class')?.includes('form-error')) {
            this.renderer.removeClass(elem, 'form-error');
         }
         if (elem?.getAttribute('class')?.includes('ctrl-form-error')) {
            this.renderer.removeClass(elem, 'ctrl-form-error');
         }
         if (lbl?.getAttribute('class')?.includes('form-error')) {
            this.renderer.removeClass(lbl, 'form-error');
         }
         if (compMessage?.getAttribute('class').includes('form-error')) {
            this.renderer.removeClass(compMessage, 'form-error');
         }
         const calendar = this.element.nativeElement.parentElement.querySelector('.p-inputtext');
         if (calendar) {
            if (calendar?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(calendar, 'form-error');
            }
            if (calendar?.getAttribute('class')?.includes('ctrl-calendar-error')) {
               this.renderer.removeClass(calendar, 'ctrl-calendar-error');
            }
            const pButton = this.element.nativeElement.parentElement.querySelector('.p-button');
            if (pButton?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(pButton, 'form-error');
            }
            if (pButton?.getAttribute('class')?.includes('ctrl-button-calendar-error')) {
               this.renderer.removeClass(pButton, 'ctrl-button-calendar-error');
            }
         }
         const autocomplete = this.element.nativeElement.parentElement.querySelector('.ng-autocomplete');
         if (autocomplete) {
            if (autocomplete?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(autocomplete, 'form-error');
            }
            const inputAutocomplete = this.element.nativeElement.parentElement.querySelector('input');

            if (inputAutocomplete?.getAttribute('class')?.includes('ng-autocomplete-error')) {
               this.renderer.removeClass(inputAutocomplete, 'ng-autocomplete-error');
            }
            if (inputAutocomplete?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(inputAutocomplete, 'form-error');
            }
         }
         const ckeditor = this.element.nativeElement.parentElement.querySelector('.ck-editor');
         if (ckeditor) {
            if (ckeditor?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(ckeditor, 'form-error');
            }
            const inputCkeditor = this.element.nativeElement.parentElement.querySelector('.ck-content');

            if (inputCkeditor?.getAttribute('class')?.includes('ck-editor-error')) {
               this.renderer.removeClass(inputCkeditor, 'ck-editor-error');
            }
            if (inputCkeditor?.getAttribute('class')?.includes('form-error')) {
               this.renderer.removeClass(inputCkeditor, 'form-error');
            }
         }
      }
   }
}

import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor() { }
  notificacionExito(mensaje:string):void{
    Swal.fire({
      html:`<div class="alert">
            <p>
            <i class="bi bi-check-circle-fill iconoNotificacion"></i> &nbsp; ${mensaje}</p>
            </div>`,
      backdrop: false,
      background: "#158361",
      padding: 0,
      heightAuto: false,
      width: "100%",
      showConfirmButton: false,
      grow:"row",
      position: "bottom",
      customClass: {
        container: 'containerNotificacion',
        content: 'contentNotificacionExito',
        popup: 'popupNotificacion'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
      },
      timer:5000
    });
  }

  notificacionError(mensaje:string):void{
    Swal.fire({
      html:`<div class="alert">
            <p>
            <i class="bi bi-x-circle-fill iconoNotificacion"></i> &nbsp; ${mensaje}</p>
            </div>`,
      backdrop: false,
      background: "#D11F3E",
      padding: 0,
      heightAuto: false,
      width: "100%",
      showConfirmButton: false,
      position: "bottom",
      customClass: {
        container: 'containerNotificacion',
        content: 'contentNotificacionError'
      },
      allowOutsideClick: true,
      showClass: {
        popup: 'animate__animated animate__fadeInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
      },
      timer: 5000
    });
  }

  notificacionAviso(mensaje:string):void{
    Swal.fire({
      html:`<div class="alert">
            <p>
            <i class="bi bi-check-circle-fill iconoNotificacion"></i> &nbsp; ${mensaje}</p>
            </div>`,
      backdrop: false,
      background: "#0943B5",
      padding: 0,
      heightAuto: false,
      width: "100%",
      showConfirmButton: false,
      position: "bottom",
      customClass: {
        container: 'containerNotificacion',
        content: 'contentNotificacionAviso'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
      },
      timer: 5000
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { columnsByPin } from '@swimlane/ngx-datatable';
import { OAuthService } from 'angular-oauth2-oidc';

import { Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { RegisterService } from 'src/app/services/register.service';
import { AuthModel } from 'src/app/shared/models';
import { EqualValidator } from 'src/app/shared/validators/equals.validators';
import { AlertaModalService } from '../../services/alerta-modal.service';

@Component({
   selector: 'jhi-register',
   templateUrl: './register.component.html',
   styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
   subcriptions: Subscription = new Subscription();

   errors?: string;
   visiblepws = false;
   tipodocumento?: Array<any>;

   styleGuide = {
      caretClass: 'caret',
      selectBoxClass: 'dropdown-wrapper',
      selectMenuClass: 'dropdown',
      optionsClass: 'option',
   };
   registerForm: FormGroup;

   entidadfinderData: any;
   entidadId: any;
   visiblePwdConfirmation: boolean;
   validatorsForm: any[];
   rtaSearchPriv = { entidad: null };
   showOptionBand = false;
   namePrivate: string;
   bandPrivate: boolean = false;

   bandSearchEntity: boolean = false;

   get claims(): AuthModel {
      return this.oauthService.getIdentityClaims() as AuthModel;
   }
   get tipoIdentificacion(): string {
      return this.claims.TipoIdentificacion;
   }
   get identificacion(): string {
      return this.claims.Identificacion;
   }
   get primerNombre(): string {
      return this.claims.PrimerNombre;
   }
   get segundoNombre(): string {
      return this.claims.SegundoNombre;
   }
   get primerApellido(): string {
      return this.claims.PrimerApellido;
   }
   get segundoApellido(): string {
      return this.claims.SegundoApellido;
   }
   get telefono(): string {
      return this.claims.Telefono.replace(' ', '');
   }

   get entidad(): string {
      return this.registerForm.get('entidad')?.value;
   }

   constructor(
      private router: Router,
      private registerService: RegisterService,
      private alertaModal: AlertaModalService,
      private notificaciones: NotificacionesService,
      private oauthService: OAuthService
   ) {}

   ngOnInit(): void {
      this.registerForm = new FormGroup({
         tipoIdentificacionId: new FormControl(this.tipoIdentificacion),
         numeroIdentificacion: new FormControl(this.identificacion),
         primerNombre: new FormControl(this.primerNombre),
         segundoNombre: new FormControl(this.segundoNombre),
         primerApellido: new FormControl(this.primerApellido),
         segundoApellido: new FormControl(this.segundoApellido),
         entidadId: new FormControl(),
         entidad: new FormControl([Validators.required]),
         //         entidadId: new FormControl(),
         cargo: new FormControl(undefined, [Validators.required]),
         numeroCelular: new FormControl(this.telefono),
         otroNumeroTelefonico: new FormControl(null, [Validators.pattern('[0-9]*'), Validators.minLength(7), Validators.maxLength(10)]),
         correoEletronico: new FormControl(null, [
            Validators.required,
            Validators.email,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
         ]),
         ccorreoEletronico: new FormControl(null, [Validators.required]),
      });
      this.validatorsForm = [EqualValidator('correoEletronico', 'ccorreoEletronico')];
      this.registerForm.setValidators(this.validatorsForm);
      this.subcriptions
         .add(
            this.registerForm.get('entidad').valueChanges.subscribe((value) => {
               if (value === 'publica') {
                  this.showOptionBand = true;
                  this.registerForm.get('entidadId').setValue(null);
                  this.rtaSearchPriv.entidad = null;
               } else {
                  this.bandSearchEntity = false;
                  this.showOptionBand = true;
               }
            })
         )
         .add(
            // Actualización servicio de tipo de identificación
            this.registerService.tipoidentificacion().subscribe(
               (result) => {
                  this.tipodocumento = result;
               },
               () => {}
            )
         );
   }

   ngOnDestroy(): void {
      this.subcriptions.unsubscribe();
   }

   receiveEntity($event) {
      this.rtaSearchPriv = $event;
   }

   receiveForm($event: boolean) {
      this.bandSearchEntity = $event;
   }

   register(): void {
    
       if (this.rtaSearchPriv.entidad != null) {
         this.registerForm.get('entidadId').setValue(this.rtaSearchPriv.entidad);
      }
      const { ccorreoEletronico, entidad, entidadId, tipoIdentificacionId, ...data } = this.registerForm.value;

      data.entidadId = entidadId.id;

      data.tipoIdentificacionId = this.tipodocumento?.find((x) => x.sigla === this.tipoIdentificacion)?.id;

      const respuesta = Object.assign(data, this.rtaSearchPriv);
      

      if (this.registerForm.valid) {
         this.subcriptions.add(
            this.registerService.save(respuesta).subscribe(
               (responseService) => {
                  this.alertaModal
                     .modalConfirmacion(
                        '¡Usuario registrado!',
                        '<b>Su registro de usuario se enviará a aprobación y se notificará por correo electrónico</b>, en caso de no ser notificado por favor autenticarse en SIGMI.'
                     )
                     .then((result) => {
                        if (result.isConfirmed) {
                           this.router.navigate(['/']);
                           this.oauthService.logOut(false);
                           this.oauthService.revokeTokenAndLogout();
                        }
                        if (result.isDismissed) {
                           this.router.navigate(['/']);
                           this.oauthService.logOut(false);
                           this.oauthService.revokeTokenAndLogout();
                        }
                        this.notificaciones.notificacionExito(
                           'Recuerde que la contraseña del usuario corresponderá a la misma contraseña del usuario registrado en el Autenticador Digital.'
                        );
                     });
                  this.registerForm.reset();
               },
               (error) => {
                  const menssage = error?.error?.detail?.includes('adm_usuario.usuario_adm_entidad_UNIQUE')
                     ? 'Usuario ya existe en esa entidad'
                     : error?.error?.mensaje
                     ? error?.error?.mensaje
                     : 'Error al intentar crear el usuario';
                  this.alertaModal.modalError('¡Error!', menssage);
               }
            )
         );
      } 
   }

   changepwd(): void {
      this.visiblepws = this.visiblepws ? false : true;
   }
   changePwdConfirmation(): void {
      this.visiblePwdConfirmation = this.visiblePwdConfirmation ? false : true;
   }

   changeEntidadesMesa(dato: any) {
     
      this.registerForm.get('entidadId').setValue(dato);
      this.bandSearchEntity = true;
   }
   onChangeSearch(type?: any) {
      var requestData = {
         nombreEntidad: type,
      };
      this.registerService
         .postPublicData('entidad/finderTopFive', requestData)
         .toPromise()
         .then((responseData: any) => {
            this.entidadfinderData = responseData.map(({ nombre, ...data }: any) => {
               const nombreCap = nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase();
               return {
                  nombre: nombreCap,
                  ...data,
               };
            });
         });
   }

   clearSearch() {
      this.bandSearchEntity=false;
      this.entidadfinderData = undefined;
      this.registerForm.get('entidadId').setValue(null);
   }
   returnTypeIdentify = () => this.tipodocumento?.find((x) => x.sigla === this.tipoIdentificacion)?.nombre;
}

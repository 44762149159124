import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
   issuer: environment.CLIENT_URL,
   redirectUri: window.location.origin + '/inicio',
   silentRefreshRedirectUri: window.location.origin + '/inicio',
   clientId: environment.CLIENT_ID,
   responseType: 'code',
   scope: 'openid profile email offline_access ccd_api',
   showDebugInformation: true,
   sessionChecksEnabled: true,
   useIdTokenHintForSilentRefresh: false
};

<app-header-site></app-header-site>
<!-- Body -->
<div class="containerRegister">
   <div class="container pt-3 pb-5">
      <div class="row">
         <div class="col-md-4 col-sm-12">
            <div class="headline-xl text-left">Sistema de Información de Gestión del Marco de Interoperabilidad</div>
            <div class="body-1 text-justify">
               Sistema de información que permite publicar elementos de dato estandarizados de acuerdo con el estándar de Lenguaje Común de
               Intercambio de Información - LCII bajo el Marco de Interoperabilidad para Gobierno Digital.
            </div>
         </div>
         <div class="col-md-8 col-sm-12">
            <div class="content-register">
               <div class="row mb-5">
                  <div class="col">
                     <div class="headline-l">Registrar nuevo usuario</div>
                  </div>
               </div>
               <form [formGroup]="registerForm">
                  <div class="row mb-3">
                     <div class="col">
                        <label class="titleForm">Tipo de identificación </label>
                        <div class="body-1">{{ returnTypeIdentify() }}</div>
                     </div>
                     <div class="col">
                        <label class="titleForm">Número de identificación</label>
                        <div class="body-1">{{ identificacion }}</div>
                     </div>
                  </div>
                  <div class="row mb-3">
                     <div class="col">
                        <label class="titleForm">Primer nombre </label>
                        <div class="body-1">{{ primerNombre }}</div>
                     </div>
                     <div class="col">
                        <label class="titleForm">Segundo nombre </label>
                        <div class="body-1">{{ segundoNombre }}</div>
                     </div>
                  </div>
                  <div class="row mb-3">
                     <div class="col">
                        <label class="titleForm">Primer apellido </label>
                        <div class="body-1">{{ primerApellido }}</div>
                     </div>
                     <div class="col">
                        <label class="titleForm">Segundo apellido </label>
                        <div class="body-1">{{ segundoApellido }}</div>
                     </div>
                  </div>
                  <div class="row mb-3">
                     <div class="col">
                        <label class="titleForm">Número celular </label>
                        <div class="body-1">{{ telefono }}</div>
                     </div>
                     <div class="col">
                        <label for="otroNumeroTelefonico" class="textoLabel body-1 mb-1">Otro número telefónico</label>
                        <input
                           type="text"
                           class="form-control input-flat"
                           id="otroNumeroTelefonico"
                           formControlName="otroNumeroTelefonico"
                           placeholder="000000000"
                           [validCtrl]="[
                              { key: 'minlength', message: 'Campo no puede contener menos de 7 dígitos' },
                              { key: 'maxlength', message: 'Campo no puede contener más de 10 dígitos' },
                              { key: 'pattern', message: 'Contiene caracteres no validos' }
                           ]"
                        />
                     </div>
                  </div>
                  <div class="row mb-3">
                     <div class="col">
                        <label for="correoEletronico" class="textoLabel body-1 mb-1">Correo electrónico institucional o corporativo*</label>
                        <input
                           type="text"
                           class="form-control"
                           id="correoEletronico"
                           formControlName="correoEletronico"
                           placeholder="correo@mintic.gov.co"
                           [validCtrl]="[
                              { key: 'required', message: 'Correo electronico es requerido' },
                              { key: 'email', message: 'No tiene el formato correcto user@dominio.com' },
                              { key: 'pattern', message: 'No tiene el formato correcto user@dominio.com' },
                              { key: 'notmach', message: 'La confirmación no coincide' }
                           ]"
                        />
                     </div>
                     <div class="col">
                        <label for="ccorreoEletronico" class="textoLabel body-1 mb-1"
                           >Confirmar correo electrónico institucional o corporativo*</label
                        >
                        <input
                           type="text"
                           class="form-control"
                           id="ccorreoEletronico"
                           formControlName="ccorreoEletronico"
                           placeholder="correo@mintic.gov.co"
                           jhiMask
                           [validCtrl]="[
                              { key: 'required', message: 'Confirmación correo electronico es requerido' },
                              { key: 'notmach', message: 'La confirmación no coincide' }
                           ]"
                        />
                     </div>
                  </div>
                  <div class="row mb-3" [ngClass]="{ 'mb-5': !entidad }">
                     <label> Entidad*</label>
                     <div class="col col-md-4">
                        <label class="radiobutton-govco">
                           <input type="radio" id="idPublica" value="publica" name="entidad" formControlName="entidad" />
                           <label for="idPublica">Pública</label>
                        </label>
                     </div>
                     <div class="col col-md-3">
                        <label class="radiobutton-govco">
                           <input type="radio" id="idPrivada" value="privada" name="entidad" formControlName="entidad" />
                           <label for="idPrivada">Privada</label>
                        </label>
                     </div>

                     <div class="col col-md-5">
                        <label class="radiobutton-govco">
                           <input type="radio" id="idIES" value="ies" name="entidad" formControlName="entidad" />
                           <label for="idIES">Educación Superior</label>
                        </label>
                     </div>
                  </div>

                  <ng-container *ngIf="showOptionBand">
                     <ng-container *ngIf="entidad === 'publica'; else elseTemplate">
                        <div class="row mb-3">
                           <div class="col">
                              <div class="ng-autocomplete">
                                 <label for="idEntidad" class="body-1 mb-1">Ingrese el nombre de la entidad pública*</label>
                                 <ng-autocomplete
                                    id="idEntidad"
                                    [data]="entidadfinderData"
                                    minQueryLength="3"
                                    [searchKeyword]="'nombre'"
                                    (selected)="changeEntidadesMesa($event)"
                                    (inputChanged)="onChangeSearch($event)"
                                    (inputCleared)="clearSearch()"
                                    [itemTemplate]="itemTemplate"
                                    debounceTime="500"
                                    [notFoundTemplate]="notFoundTemplate"
                                    placeholder="Buscar entidad"
                                    formControlName="entidadId"
                                    [validCtrl]="[
                                       {
                                          key: 'required',
                                          message: 'Entidad es requerido'
                                       }
                                    ]"
                                 >
                                 </ng-autocomplete>
                                 <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.nombre"></a>
                                 </ng-template>
                                 <ng-template #notFoundTemplate let-notFound> No hay resultados </ng-template>
                              </div>
                           </div>
                        </div>
                     </ng-container>
                     <ng-template #elseTemplate>
                        <app-entity-search (dataEntity)="receiveEntity($event)" (sendFormValid)="receiveForm($event)" [ies]="entidad === 'ies'"></app-entity-search>
                     </ng-template>
                     <div class="row mb-5">
                        <div class="col-md-6">
                           <label for="cargo" class="textoLabel body-1 mb-1">Cargo*</label>
                           <input
                              type="text"
                              class="form-control"
                              id="cargo"
                              formControlName="cargo"
                              placeholder="Nombre del cargo"
                              [validCtrl]="[{ key: 'required', message: 'Cargo es requerido' }
                                         
                           ]"
                           />
                        </div>
                     </div>
                  </ng-container>

                  <div class="row pt-2 mb-2">
                     <div class="col">
                        <button class="btn btn-round btn-high" (click)="register()" [disabled]="((registerForm.invalid == true ) || (this.bandSearchEntity == false))">
                           REGISTRAR
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>

<div
   class="container pt-3 content-table"
   [ngClass]="{ 'border-table': border, 'content-table-general': tblGeneral, titlePaddingContent: titlePadding }"
>
   <ng-container *ngIf="filter && title">
      <div class="row ps-3 pe-3" [ngClass]="{ titlePadding: titlePadding }">
         <div class="col" [ngClass]="{ titlePadding: titlePadding }">
            <h3 class="mt-4 headline-l {{ title?.class }}" [ngClass]="{ 'modal-padding': modalPadding }">{{ title.text }}</h3>
            <p *ngIf="title?.textMessage" [class]="title?.classMessage">{{ title.textMessage }}</p>
            <span [class]="title?.classEvent ? title?.classEvent : 'link-table'" (click)="emitterEvent('title')">{{ title.textEvent }} </span>
         </div>
      </div>
   </ng-container>
   <div class="row mb-4 pt-2 ps-3 pe-3" [ngClass]="{ titlePadding: titlePadding, 'modal-padding': modalPadding }">
      <div class="col-10" [ngClass]="{ 'd-flex flex-column justify-content-end': filter, 'p-0': titlePadding }">
         <ng-container *ngIf="filter">
            <div class="row">
               <div class="col-md-3">
                  <label for="pageSize">Buscar por</label>
                  <ng-select
                     [options]="optionsSelect"
                     placeholder="Seleccione: "
                     [items]="optionsSelect"
                     [displayKey]="displayKey"
                     bindLabel="name"
                     [styleGuide]="styleGuide"
                     [isDatalist]="isDataList"
                     [(ngModel)]="filterColumn"
                     (change)="clearFilter()"
                     [clearable]="false"
                     [searchable]="false"
                  >
                  </ng-select>
               </div>
               <div class="col-md-9 d-flex flex-column justify-content-end">
                  <div class="position-relative">
                     <input
                        id="Search"
                        class="form-control px-3 barSearchSigmi"
                        type="search"
                        style="width: 100%"
                        placeholder="Buscar por palabra"
                        [autocomplete]="false"
                        [(ngModel)]="filterValue"
                        (input)="clearFilter()"
                     />
                     <button type="button" class="btn-search-sigmi" (click)="eventFilter()">
                        <span class="bi bi-search"></span>
                     </button>
                  </div>
               </div>
            </div>
         </ng-container>
         <ng-container *ngIf="!filter && title">
            <h3 class="mt-4 headline-l {{ title?.class }}">{{ title.text }}</h3>
            <p [class]="title?.classMessage">{{ title.textMessage }}</p>
            <span [class]="title?.classEvent ? title?.classEvent : 'link-table'" (click)="emitterEvent('title')">{{ title.textEvent }} </span>
         </ng-container>
      </div>
      <div class="col-md-2 d-flex flex-column justify-content-end" [ngClass]="{ vtclHeigth: verticalHth }" *ngIf="numPages !== false">
         <label class="body-1 mb-1" for="pageSize">Resultados pág.</label>
         <ng-select
            [options]="optionsNumber"
            placeholder="Seleccione: "
            [items]="optionsNumber"
            [displayKey]="displayKey"
            bindLabel="name"
            [styleGuide]="styleGuide"
            [isDatalist]="isDataList"
            [(ngModel)]="pageSize"
            id="pageSize"
            [clearable]="false"
            [searchable]="false"
            (change)="pageSizeTable = pageSize"
         >
         </ng-select>
      </div>
   </div>
   <div class="row">
      <div class="col p-0">
         <table class="table" [ngClass]="{ tablePadding: borderContentTbl, tableBorderLeft: borderLeftTable }">
            <thead>
               <tr class="table-head-sigmi">
                  <th
                     *ngFor="let column of columns; let i = index"
                     [appSortTable]="rows"
                     [column]="column.prop"
                     [sortable]="column.sortable"
                     (click)="orderWithCheckbox()"
                     [ngClass]="{ 'without-sort': column.sortable === false && column.prop !== 'radio' && column.prop !== 'checkbox' }"
                     [ngStyle]="{ width: column.minWidth + 'px' }"
                     class="pt-2 pb-2"
                     [class]="column?.cellThClass"
                  >
                     <div
                        class="sigmi-column-table"
                        [ngClass]="{ 'sigmi-radio-table': column.prop === 'radio' || column.prop === 'checkbox' }"
                        [ngStyle]="{
                           'min-width': column.minWidth + 'px',
                           'max-width': column.maxWidth + 'px'
                        }"
                     >
                        <ng-container *ngIf="column.prop !== 'checkbox'; else templateCheckbox">
                           <span class="sigmi-icon sigmi-sort sort-unset" *ngIf="column.sortable !== false"></span>
                           <span class="text-column">{{ column.name }}</span>
                           <ng-container *ngIf="returnFilter(column.prop)">
                              <span
                                 class="sigmi-icon sigmi-status sigmi-filter-status"
                                 *ngIf="returnState(column.prop)"
                                 (click)="$event.stopPropagation(); setDisplay(column.prop)"
                              >
                              </span>
                              <div class="content-filter" *ngIf="displayFilter(column.prop)">
                                 <span (click)="$event.stopPropagation(); clearFilterStatus()">Total ({{ total }})</span>
                                 <span
                                    *ngFor="let select of returnSelections(column.prop)"
                                    (click)="$event.stopPropagation(); setFilterStatus(column.prop, select)"
                                 >
                                    {{ select }}({{ returnNum(column.prop, select) }})
                                 </span>
                              </div>
                           </ng-container>
                        </ng-container>
                        <ng-template #templateCheckbox>
                           <label
                              *ngIf="showHeadCheckbox"
                              class="checkbox-govco"
                              [ngClass]="{ interminate: returnInterminate() }"
                              (click)="$event.stopPropagation()"
                              (change)="selectionAll()"
                           >
                              <input type="checkbox" [id]="'head' + i" [checked]="!isSelectedAll()" />
                              <label [for]="'head' + i"></label>
                           </label>
                        </ng-template>
                     </div>
                  </th>
               </tr>
            </thead>
            <tbody>
               <ng-container *ngIf="rows?.length > 0; else elseTemplate">
                  <ng-container
                     *ngFor="
                        let row of rows
                           | filterCollection : { key: pipeKey, value: pipeValue, equals: pipeEquals }
                           | paginate
                              : {
                                   itemsPerPage: pageSizeTable,
                                   currentPage: page,
                                   totalItems: (rows | filterCollection : { key: pipeKey, value: pipeValue, equals: pipeEquals })?.length
                                };
                        let i = index
                     "
                  >
                     <tr [class]="row?.rowClass">
                        <!--Estilo fila-->
                        <td
                           *ngFor="let column of columns"
                           [ngClass]="{
                              'column-radio': column.prop === 'radio' || column.prop === 'checkbox',
                              'column-accion': column.prop === 'accion' || column.prop === 'actividad',
                              'p-0': column.prop === 'versionDoc'
                           }"
                           [class]="column?.cellClass"
                        >
                           <ng-container *ngIf="returnState(column.prop); else templateColumns">
                              <span [class]="returnClass(column.prop, row[column.prop])"> {{ row[column.prop] }}</span>
                              <span [class]="returnSubClass(column.prop, row[column.prop])" *ngIf="returnSubstate(column.prop, row)">{{
                                 returnSubdata(column.prop, row)
                              }}</span>
                           </ng-container>
                           <ng-template #templateColumns>
                              <ng-container [ngSwitch]="column.prop">
                                 <ng-container *ngSwitchCase="columnsActions.includes(column.prop) ? column.prop : ''">
                                    <ng-container *ngFor="let item of returnEvents(row[column.prop]); let last = last">
                                       <span
                                          *ngIf="column.oidTooltip"
                                          [class]="'link-table ' + (returnDisabled(item, row) ? column?.cellClassAction || '' : '')"
                                          [ngClass]="{ disabled: returnDisabled(item, row) }"
                                          (click)="oidToolTip(item)">
                                          {{ item }} 
                                       </span>
                                       <span
                                          *ngIf="!column.oidTooltip"
                                          [class]="'link-table ' + (returnDisabled(item, row) ? column?.cellClassAction || '' : '')"
                                          [ngClass]="{ disabled: returnDisabled(item, row) }"
                                          (click)="emitterEvent(item, row, undefined, column.prop)"
                                       >
                                          {{ item }}
                                       </span>

                                       {{ separator && !last ? ' / ' : '' }}
                                    </ng-container>
                                 </ng-container>
                                 <ng-container *ngSwitchCase="'radio'">
                                    <ng-container *ngIf="returnType(row[column.prop]); else arrowTemplate">
                                       <label class="radiobutton-govco d-block">
                                          <input
                                             type="radio"
                                             [id]="row[column.prop]"
                                             [value]="row[column.prop]"
                                             (click)="emitterEvent(column.prop, row)"
                                             [(ngModel)]="valueRadio"
                                             [disabled]="returnRadioDisabled(row)"
                                          />
                                          <label [for]="row[column.prop]"></label>
                                       </label>
                                    </ng-container>
                                    <ng-template #arrowTemplate>
                                       <div
                                          class="icon-arrow-table"
                                          [ngClass]="{ active: row[columnSubRows.key] === displayRowid }"
                                          (click)="displayRows(row)"
                                       >
                                          <span class="sigmi-icon sigmi-arrow-up"></span>
                                       </div>
                                    </ng-template>
                                 </ng-container>
                                 <ng-container *ngSwitchCase="'checkbox'">
                                    <label class="checkbox-govco">
                                       <input type="checkbox" [id]="'opcion' + i" [checked]="row[column.prop]" (click)="selectRow(i, row)" />
                                       <label [for]="'opcion' + i"></label>
                                    </label>
                                 </ng-container>
                                 <ng-container *ngSwitchCase="'versionDoc'">
                                    <div *ngIf="row.flagHistorico === false" class="col-md-6 align-middle">
                                       <p class="columnText" style="margin-top: revert !important">{{ row.fuenteInformacion }}</p>
                                    </div>
                                    <div *ngIf="row.flagHistorico" class="col-md-6">
                                       <app-sigmi-upload
                                          [nameCtrl]="'row.controlFile'"
                                          [_files]="row.attachments"
                                          [download]="true"
                                       ></app-sigmi-upload>
                                    </div>
                                 </ng-container>
                                 <ng-container *ngSwitchDefault> {{ row[column.prop] }} </ng-container>
                              </ng-container>
                           </ng-template>
                        </td>
                     </tr>
                     <ng-container *ngIf="row[columnSubRows?.key] === displayRowid">
                        <ng-container *ngIf="columnSubRows?.type === 'template'; else rowsTemplate">
                           <tr>
                              <td class="p-0" [ngStyle]="{ background: '#f6f8f9' }" [attr.colspan]="columns.length">
                                 <div [innerHTML]="returnSubRows(row)"></div>
                              </td>
                           </tr>
                        </ng-container>
                        <ng-template #rowsTemplate>
                           <ng-container *ngFor="let subRow of returnSubRows(row); let a = index">
                              <tr>
                                 <td *ngFor="let column of columns" [ngClass]="{ 'column-radio': column.prop === 'radio' }">
                                    <ng-container [ngSwitch]="column.prop">
                                       <ng-container *ngSwitchCase="['accion', 'actividad']">
                                          <ng-container *ngFor="let item of returnEvents(subRow[column.prop]); let last = last">
                                             <span class="link-table" (click)="emitterEvent(item, subRow, 'subRow')">{{ item }} </span>
                                             {{ !last ? ' / ' : '' }}
                                          </ng-container>
                                       </ng-container>
                                       <ng-container *ngSwitchCase="'radio'">
                                          <label class="radiobutton-govco d-block">
                                             <input
                                                type="radio"
                                                [id]="row[columnSubRows.parentKey] + subRow[column.prop]"
                                                [value]="row[columnSubRows.parentKey] + subRow[column.prop]"
                                                (click)="emitterEvent(column.prop, subRow, 'subRow')"
                                                [(ngModel)]="valueRadioSubRow"
                                             />
                                             <label [for]="row[columnSubRows.parentKey] + subRow[column.prop]"></label>
                                          </label>
                                       </ng-container>
                                       <ng-container *ngSwitchDefault> {{ subRow[column.prop] }} </ng-container>
                                    </ng-container>
                                 </td>
                              </tr>
                           </ng-container>
                        </ng-template>
                     </ng-container>
                  </ng-container>
               </ng-container>
               <ng-template #elseTemplate>
                  <tr>
                     <td class="title-table text-center" [attr.colspan]="columns?.length">
                        <div [class]="messageEmpty?.classMessage ? messageEmpty?.classMessage : 'message-empty-table'">
                           <span [class]="messageEmpty?.classFont ? messageEmpty?.classFont : ''">{{ messageEmpty?.message }}</span>
                        </div>
                     </td>
                  </tr>
               </ng-template>
            </tbody>
         </table>
      </div>
   </div>
   <div class="row justify-content-center" [ngClass]="{ tableContainerPagination: borderContentTbl }" *ngIf="rows?.length > 0 && reporte">
      <div class="col-md-12 text-center pt-4 pb-2">
         <div class="reporteBox">
            <div class="textReporte">
               Descargue el catálogo completo de los datos de la tabla <span class="link-table" (click)="downloadTable()"> {{ reporteLabel }} </span>
            </div>
         </div>
      </div>
   </div>
   <ng-container *ngIf="pagination !== false">
      <div class="row" *ngIf="rows?.length > 0">
         <div class="col-md-12 text-center pt-4 pb-2" [ngClass]="{ tableContainerPagination: borderContentTbl }">
            <pagination-controls
               *ngIf="rows?.length >= 1"
               (pageChange)="onPaginatorChange($event)"
               (pageBoundsCorrection)="onPaginatorChange($event)"
               maxSize="6"
               directionLinks="true"
               autoHide="false"
               responsive="true"
               previousLabel="Anterior"
               contenteditable="false"
               nextLabel="Siguiente"
               screenReaderPaginationLabel="Paginador"
               screenReaderPageLabel="Página"
               screenReaderCurrentLabel="Usted está en la página"
               class="encapsulado"
            >
            </pagination-controls>
         </div>
      </div>
   </ng-container>
</div>


<div class="modal" tabindex="-1" role="dialog" [style.display]="oidModal ? 'block' : 'none'">
   <div class="modal-dialog  modal-dialog-centered" role="document"  >
     <div class="modal-content" style="background-color: #e6effd !important; border-color: #4B4B4B !important; border: 3px solid !important;">
       <div class="modal-header">
         <h5 class="modal-title">Estructura Identificador Objeto Digital - OID </h5>
           <a href="javascript:void(0)" (click)="oidModal = false" style="text-decoration: none; font-size: x-large !important;"><span >&times;</span></a>
       </div>
       <div class="modal-body">
         <div class="container">
           <div class="row" *ngFor="let item of oidDetailsList">
            <div class="col">
               <span style="color: #4672C8 !important;"><strong>{{item.oid}}</strong></span> - {{item.nombre}}
            </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>

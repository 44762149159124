import { InjectionToken } from '@angular/core';

const convertBase64ToBlob = (base64Archivo: string, type: string) => {
   const decodedData = atob(base64Archivo);
   const uInt8Array = new Uint8Array(decodedData.length);
   for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
   }
   return new Blob([uInt8Array], { type: type });
};
export const sharedFunctions = {
   returnconvertBase64ToBlob: convertBase64ToBlob,
};

export const SHAREDFUNCTIONS = new InjectionToken('SHAREDFUNCTIONS', {
   providedIn: 'root',
   factory: () => sharedFunctions,
});
